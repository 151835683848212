var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(!this.isEditAble),expression:"!this.isEditAble"}],attrs:{"outlined":"","dense":"","label":"Current Address"},model:{value:(_vm.currentAddress),callback:function ($$v) {_vm.currentAddress=$$v},expression:"currentAddress"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(!this.isEditAble),expression:"!this.isEditAble"}],attrs:{"outlined":"","dense":"","label":"Mobile"},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}}),_c('v-autocomplete',{staticClass:"tw-text-xs reasons tw-text-right tw-RTl",attrs:{"clearable":"","dense":"","multiple":"","outlined":"","menu-props":{
          closeOnClick: true,
          closeOnContentClick: true,
        },"items":_vm.reason,"item-text":"note","label":"Reason to Stop","rules":[_vm.required]},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-list-item-content',[_c('div',{staticClass:"dhivehi tw-flex tw-justify-end tw-break-all"},[_c('p',{staticClass:"tw-text-sm tw-text-right",domProps:{"textContent":_vm._s(`${item.note}`)}})])])]}}]),model:{value:(_vm.selectedReason),callback:function ($$v) {_vm.selectedReason=$$v},expression:"selectedReason"}}),_c('v-select',{staticClass:"tw-text-xs",attrs:{"clearable":"","dense":"","multiple":"","outlined":"","items":_vm.actions,"label":"Action Taken","rules":[_vm.required],"menu-props":{
          closeOnClick: true,
          closeOnContentClick: true,
        }},model:{value:(_vm.selectedActions),callback:function ($$v) {_vm.selectedActions=$$v},expression:"selectedActions"}}),_c('label',{staticClass:"tw-w-full tw-text-xs tw-text-appColor tw-text-right tw-justify-end tw-flex tw-pb-0.5"},[_vm._v(" މިބައި ފުރިހަމަ ކުރާނީ ޖޫރިމަނާ ކުރާނަމަ*")]),_c('v-select',{staticClass:"tw-text-xs",attrs:{"clearable":"","dense":"","multiple":"","outlined":"","items":_vm.fine,"label":"Fine","menu-props":{
          closeOnClick: true,
          closeOnContentClick: true,
        }},model:{value:(_vm.selectedFine),callback:function ($$v) {_vm.selectedFine=$$v},expression:"selectedFine"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Location","prefix":"SH. MILANDHOO/","rules":[_vm.required]},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}}),_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(!this.isEditAble),expression:"!this.isEditAble"}],staticClass:"tw-text-xs",attrs:{"clearable":"","dense":"","multiple":"","outlined":"","items":_vm.officers,"item-text":'service_number',"item-value":'id',"label":"Officers","rules":[_vm.required],"menu-props":{
          closeOnClick: true,
          closeOnContentClick: true,
        }},model:{value:(_vm.selectedOfficers),callback:function ($$v) {_vm.selectedOfficers=$$v},expression:"selectedOfficers"}}),_c('v-textarea',{attrs:{"dense":"","outlined":"","label":"Notes"},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}}),_c('v-btn',{staticClass:"tw-my-2",attrs:{"elevation":"2","color":"primary","width":"100%"},on:{"click":function($event){return _vm.validate()}}},[_c('span',{staticClass:"tw-text-white"},[_vm._v(_vm._s(!this.isEditAble ? "SUBMIT" : "UPDATE"))])])],1),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }