<template>
  <v-expansion-panels accordion flat>
    <v-expansion-panel>
      <v-expansion-panel-header
        class="tw-text-sm tw-font-semibold tw-text-appColor"
        >View All Interaction</v-expansion-panel-header
      >
      <v-expansion-panel-content>
        <v-list dense v-for="record in records" :key="record.id">
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="tw-py-0.5">
                Date:
                <span class="tw-font-normal">
                  {{ formateDate(record.createdAt) }}
                </span>
                <span class="tw-font-bold"
                  >&nbsp;&nbsp;({{ Ago(record.createdAt) }})</span
                >
              </v-list-item-title>
              <v-list-item-title class="tw-py-0.5"
                >Vehicle No:
                <span
                  class="tw-font-normal tw-overflow-auto tw-whitespace-pre-line"
                >
                  {{ record.vehicle_no }}</span
                ></v-list-item-title
              >
              <v-list-item-title class="tw-py-0.5"
                >Reason to Stop:
                <span class="tw-font-normal dhivehi tw-flex tw-justify-end">
                  {{ record.reason_to_stop }}
                </span>
              </v-list-item-title>
              <v-list-item-title class="tw-py-0.5"
                >Action Taken:
                <span class="tw-font-normal"> {{ record.action_taken }}</span>
              </v-list-item-title>
              <v-list-item-title class="tw-py-0.5">
                Interaction:
                <span
                  class="tw-font-normal tw-text-xs tw-capitalize normal tw-overflow-auto tw-whitespace-pre-line"
                >
                  {{ Ago(record.createdAt) }}, Location: Sh.Milandhoo /
                  {{ record.location }}, Details:
                  {{ record.notes }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import moment from "moment";

export default {
  props: ["records"],
  methods: {
    formateDate(value) {
      const date = moment(value).format("DD/MM/YYYY HH:mm:ss");
      return date;
    },
    Ago: function (date) {
      return moment(date).fromNow();
    },
  },
};
</script>

<style scoped>
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
