<template>
  <v-card class="tw-p-2">
    <v-card-text>
      <v-autocomplete
        v-model="model"
        :loading="isLoading"
        :search-input.sync="search"
        :items="items"
        :filter="customFilter"
        outlined
        dense
        hide-no-data
        hide-selected
        item-text="name"
        item-value="id"
        label="Person"
        placeholder="ID card / Name / Address"
        prepend-icon="mdi-account-search"
        return-object
      >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-text="item.name"></v-list-item-title>
            <v-list-item-subtitle
              v-text="
                `ID.No: ${item.id_no}, ADDRESS: ${item.permanent_address}`
              "
            ></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-card-text>
    <v-divider v-if="model"></v-divider>
    <v-expand-transition>
      <v-list v-if="model" dense>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="tw-py-0.5">
              NAME:
              <span class="tw-font-normal"> {{ model.name }}</span>
            </v-list-item-title>
            <v-list-item-title class="tw-py-0.5"
              >ID NUMBER:
              <span class="tw-font-normal"> {{ model.id_no }}</span>
            </v-list-item-title>
            <v-list-item-title class="tw-py-0.5 tw-uppercase"
              >licence No:
              <span class="tw-font-normal"> {{ model.licence_no }}</span>
            </v-list-item-title>
            <v-list-item-title class="tw-py-0.5"
              >DOB: <span class="tw-font-normal">{{ getAge() }}</span>
            </v-list-item-title>
            <v-list-item-title class="tw-py-0.5">
              MOBILE:
              <span class="tw-font-normal">{{ model.mobile }}</span>
            </v-list-item-title>
            <v-list-item-title class="tw-py-0.5">
              ADDRESS:
              <span class="tw-font-normal">
                {{ model.permanent_address }}
              </span>
            </v-list-item-title>
            <v-list-item-title class="tw-py-0.5">
              CURRENT ADDRESS:
              <span class="tw-font-normal">
                {{ model.current_address }}
              </span>
            </v-list-item-title>
            <LastInteraction
              :key="model.id"
              v-if="search_person_form.length > 0 || vehicle_form.length > 0"
              :search_person_form="search_person_form"
              :vehicle_form="vehicle_form"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-expand-transition>
  </v-card>
</template>

<script>
import LastInteraction from "../interaction/LastInteraction.vue";
import api from "../../plugins/request";
import age from "s-age";
import moment from "moment";

export default {
  props: ["personData"],
  components: {
    LastInteraction,
  },

  data() {
    return {
      isLoading: false,
      model: null,
      search: null,
      items: [],
      records: [],
      search_person_form: [],
      vehicle_form: [],
    };
  },
  watch: {
    async search() {
      if (this.isLoading) return;
      if (this.items.length > 0) return;

      this.isLoading = true;

      const res = await api.post(
        "/api/v1/client?task=search-person",
        {
          searchText: "",
        },
        false
      );

      if (res.data.status) {
        this.items = res.data.data;
      }
      this.isLoading = false;
    },

    model(v) {
      this.$emit("personInfo", v);
      if (v) {
        this.getPersonRecords(v.id);
      }
    },

    personData(v) {
      if (v) {
        this.model = v;
      }
    },
  },

  methods: {
    customFilter(item, queryText) {
      const textOne = item.name.toLowerCase();
      const textTwo = item.current_address.toLowerCase();
      const textThree = item.permanent_address.toLowerCase();
      const textFour = item.id_no == null ? "" : item.id_no.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1 ||
        textThree.indexOf(searchText) > -1 ||
        textFour.indexOf(searchText) > -1
      );
    },

    async getPersonRecords(person_id) {
      const res = await api.post(
        "/api/v1/client/records?task=get-records-by-id",
        {
          person_id,
        },
        false
      );
      if (res.data.status) {
        this.records = res.data.data[0];
        if (this.records.search_person_forms.length > 0) {
          this.search_person_form = this.records.search_person_forms;
        } else {
          this.search_person_form = [];
        }

        if (this.records.vehicle_forms.length > 0) {
          this.vehicle_form = this.records.vehicle_forms;
        } else {
          this.vehicle_form = [];
        }
      }
    },

    getAge() {
      const dob = moment(this.model.dob).format("DD/MM/YYYY");
      const yearsOld = age(this.model.dob);
      const outputDate = `${dob}, (${yearsOld} yrs)`;
      return outputDate;
    },
  },
};
</script>
