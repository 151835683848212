<template>
  <div class="tw-w-full">
    <div class="tw-max-w-screen-2xl sm:tw-p-10 tw-p-4 tw-mx-auto">
      <div class="tw-max-w-screen-md tw-mx-auto">
        <search-person
          v-on:personInfo="personInfo"
          :key="personKey"
        ></search-person>

        <v-card class="tw-my-4 tw-p-6" :key="formsKey">
          <v-select
            outlined
            dense
            :items="items"
            v-model="formType"
            label="Type of Form"
          ></v-select>

          <form-person
            v-if="formType == 'STOP AND SEARCH PERSON'"
            v-on:form="add"
            :isEditAble="false"
          ></form-person>
          <form-vehicle
            v-else-if="formType == 'STOP AND SEARCH VEHICLE'"
            v-on:form="add"
          >
          </form-vehicle>
          <from-vessel
            v-else-if="formType == 'STOP AND SEARCH VESSEL'"
            v-on:form="add"
          ></from-vessel>
        </v-card>
      </div>
    </div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>

    <v-snackbar v-model="snackbar" top :color="snackbarColor">
      {{ snackbarMsg
      }}<template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import api from "../plugins/request";

import FormPerson from "../components/interaction/FormPerson.vue";
import FormVehicle from "../components/interaction/FormVehicle.vue";
import FromVessel from "../components/interaction/FromVessel.vue";
import SearchPerson from "../components/interaction/SearchPerson.vue";
export default {
  components: { SearchPerson, FormPerson, FormVehicle, FromVessel },
  data() {
    return {
      snackbar: false,
      snackbarMsg: "",
      snackbarColor: "primary",
      overlay: false,
      formType: "",
      formsKey: new Date().toString(),
      personKey: new Date().toString() + new Date().toString(),
      items: [
        "STOP AND SEARCH PERSON",
        "STOP AND SEARCH VEHICLE",
        "STOP AND SEARCH VESSEL",
        "PLACE CHECK",
      ],
      person: null,
      form: "",
    };
  },
  methods: {
    personInfo(value) {
      this.person = value;
    },

    success() {
      this.formsKey = new Date().toString();
      this.personKey = new Date().toString() + new Date().toString();
      this.person = null;
      this.form = "";
      this.snackbarColor = "success";
      this.snackbarMsg = "Record added successfully";
      this.snackbar = true;
      this.overlay = false;
    },

    async add(value) {
      this.form = value;
      if (!this.person) {
        this.snackbarColor = "red accent-2";
        this.snackbarMsg = "Select a person";
        this.snackbar = true;
      } else {
        this.overlay = true;

        if (this.form.form_type == "person_search_form") {
          const res = await api.post(
            "/api/v1/client/form?task=enter-search-person",
            {
              person: this.person,
              form: this.form,
            },
            false
          );
          if (res.data.status) {
            this.success();
          }
        } else if (this.form.form_type == "vehicle_forms") {
          const res = await api.post(
            "/api/v1/client/form?task=enter-vehicle-form",
            {
              person: this.person,
              form: this.form,
            },
            false
          );
          if (res.data.status) {
            this.success();
          }
        }
      }
    },
  },
  created() {
    const path = this.$route;
    if (path.query.type) {
      if (path.query.type == "person") {
        this.formType = "STOP AND SEARCH PERSON";
      } else if (path.query.type == "vehicle") {
        this.formType = "STOP AND SEARCH VEHICLE";
      }
    }
  },
};
</script>
