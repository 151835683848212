<template>
  <v-autocomplete
    v-model="model"
    :loading="isLoading"
    :search-input.sync="search"
    :items="items"
    :filter="customFilter"
    outlined
    dense
    hide-details
    hide-no-data
    hide-selected
    clearable
    item-text="name"
    item-value="id"
    label="Person"
    placeholder="ID card / Name / Address"
    return-object
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title v-text="item.name"></v-list-item-title>
        <v-list-item-subtitle
          v-text="`ID.No: ${item.id_no}, ADDRESS: ${item.permanent_address}`"
        ></v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import api from "../../plugins/request";

export default {
  data() {
    return {
      isLoading: false,
      model: null,
      search: null,
      items: [],
    };
  },
  watch: {
    async search() {
      if (this.isLoading) return;
      if (this.items.length > 0) return;

      this.isLoading = true;

      const res = await api.post(
        "/api/v1/client?task=search-person",
        {
          searchText: "",
        },
        false
      );

      if (res.data.status) {
        this.items = res.data.data;
      }
      this.isLoading = false;
    },

    model(v) {
      this.$emit("personInfo", v);
    },
  },

  methods: {
    customFilter(item, queryText) {
      const textOne = item.name.toLowerCase();
      const textTwo = item.current_address.toLowerCase();
      const textThree = item.permanent_address.toLowerCase();
      const textFour = item.id_no == null ? "" : item.id_no.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1 ||
        textThree.indexOf(searchText) > -1 ||
        textFour.indexOf(searchText) > -1
      );
    },
  },
};
</script>
