<template>
  <div class="tw-w-full">
    <div class="tw-max-w-screen-2xl sm:tw-p-10 tw-p-4 tw-mx-auto">
      <div class="tw-max-w-screen-md tw-mx-auto">
        <v-card class="tw-my-4 tw-p-6" :key="formKey">
          <v-form v-model="valid" ref="form">
            <h1 class="tw-text-xl tw-text-appColor tw-font-semibold">
              Edit Person
            </h1>
            <v-divider class="tw-py-2"></v-divider>
            <FindPerson v-on:personInfo="personInfo" :key="personKey" />
            <v-divider class="tw-py-2"></v-divider>
            <v-text-field
              outlined
              dense
              label="Full Name"
              v-model="name"
              :rules="[required]"
            ></v-text-field>

            <v-text-field
              outlined
              dense
              label="ID Card Number"
              v-model="id_no"
            ></v-text-field>

            <v-text-field
              outlined
              dense
              label="licence Card Number"
              v-model="licence_no"
            ></v-text-field>

            <v-radio-group v-model="sex" label="Sex" row>
              <v-radio label="Male" value="M"></v-radio>
              <v-radio label="Female" value="F"></v-radio>
            </v-radio-group>

            <v-text-field
              outlined
              dense
              label="Permanent Address"
              v-model="permanent_address"
              :rules="[required]"
            ></v-text-field>

            <v-text-field
              outlined
              dense
              label="Current Address"
              v-model="current_address"
              :rules="[required]"
            ></v-text-field>

            <v-text-field
              outlined
              dense
              label="Mobile Number"
              v-model="mobile"
            ></v-text-field>

            <v-text-field
              outlined
              dense
              label="DOB"
              v-model="dob"
              placeholder="DD/MM/YYYY"
              :rules="[dateValidator]"
            ></v-text-field>

            <v-btn
              @click="add"
              elevation="2"
              color="primary"
              class="tw-my-2"
              width="100%"
            >
              <span class="tw-text-white">Update</span>
            </v-btn>
          </v-form>
        </v-card>
      </div>
    </div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar.isOpen" top :color="snackbar.color">
      {{ snackbar.message
      }}<template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.isOpen = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import moment from "moment";
import FindPerson from "../../components/editPerson/FindPerson.vue";
import api from "../../plugins/request";
export default {
  components: { FindPerson },
  data() {
    return {
      formKey: new Date().toString(),
      personKey: new Date().toString() + new Date().toString(),

      valid: false,
      overlay: false,
      person: null,

      snackbar: {
        isOpen: false,
        message: "",
        color: "primary",
      },

      // Form
      person_id: "",
      name: "",
      id_no: "",
      licence_no: "",
      sex: "",
      permanent_address: "",
      current_address: "",
      mobile: "",
      dob: "",
    };
  },

  methods: {
    personInfo(value) {
      this.person = value;
      this.person_id = value.id;
      this.name = value.name;
      this.id_no = value.id_no;
      this.licence_no = value.licence_no == null ? "" : value.licence_no;
      this.sex = value.sex;
      this.permanent_address = value.permanent_address;
      this.current_address = value.current_address;
      this.mobile = value.mobile;
      this.dob = this.getAge(value.dob);
    },

    async add() {
      this.$refs.form.validate();

      if (!this.person) {
        this.snackbar.isOpen = true;
        this.snackbar.message = "Select a person";
        this.snackbar.color = "warning";
        return;
      }

      if (this.sex == "") {
        this.snackbar.isOpen = true;
        this.snackbar.message = "Please select a gender";
        this.snackbar.color = "warning";
        return;
      } else if (this.sex != "" && this.valid) {
        this.overlay = true;
        const res = await api.post(
          "/api/v1/client?task=edit-person",
          {
            id: this.person_id,
            name: this.name.toUpperCase(),
            id_no: this.id_no.trim().toUpperCase(),
            licence_no: this.licence_no.trim().toUpperCase(),
            sex: this.sex,
            permanent_address: this.permanent_address.toUpperCase(),
            current_address: this.current_address.toUpperCase(),
            mobile: this.mobile,
            dob: this.dob,
          },
          false
        );
        this.overlay = false;

        if (res.data.status) {
          this.success();
        } else {
          this.snackbar.isOpen = true;
          this.snackbar.message = res.data.message;
          this.snackbar.color = "red";
        }
      }
    },

    success() {
      this.formKey = new Date().toString();
      this.personKey = new Date().toString() + new Date().toString();
      this.name = "";
      this.id_no = "";
      this.licence_no = "";
      this.sex = "";
      this.permanent_address = "";
      this.current_address = "";
      this.mobile = "";
      this.dob = "";
      this.snackbar.isOpen = true;
      this.snackbar.message = "Updated successfully";
      this.snackbar.color = "success";
    },

    required(value) {
      if (value instanceof Array && value.length == 0) {
        return "Required.";
      }
      return !!value || "Required.";
    },

    dateValidator(value) {
      const reg = new RegExp(
        /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g
      );
      if (!value) {
        return "Required";
      }
      return reg.test(value) || "Invalid Date Format";
    },

    getAge(value) {
      console.log(value);
      const dob = moment(value).format("DD/MM/YYYY");
      return dob;
    },
  },
};
</script>

<style>
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
