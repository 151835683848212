<template>
  <div>
    <v-form v-model="valid" ref="form">
      <v-text-field
        outlined
        dense
        label="Mobile"
        v-model="mobile"
      ></v-text-field>

      <v-text-field
        outlined
        dense
        label="Current Address"
        v-model="currentAddress"
      ></v-text-field>

      <v-text-field
        outlined
        dense
        label="Register Number"
        v-model="registerNumber"
        :rules="[required]"
      ></v-text-field>

      <v-text-field
        outlined
        dense
        label="Registered Island"
        v-model="registeredIsland"
        :rules="[required]"
      ></v-text-field>

      <v-text-field
        outlined
        dense
        label="Captain"
        v-model="captain"
        :rules="[required]"
      ></v-text-field>

      <v-text-field
        outlined
        dense
        label="Crew"
        v-model="crew"
        :rules="[required]"
      ></v-text-field>

      <v-text-field
        outlined
        dense
        label="Boat Builder Name / Company"
        v-model="boatBuildersName"
        :rules="[required]"
      ></v-text-field>

      <v-select
        clearable
        dense
        outlined
        class="tw-text-xs"
        label="Type of Vessel"
        :items="typeOfVessel"
        v-model="selectedVesselType"
        :rules="[required]"
      ></v-select>

      <v-text-field
        outlined
        dense
        label="Size of Vessel"
        v-model="size"
        :rules="[required]"
      ></v-text-field>

      <v-radio-group
        v-model="passengerUfulumugeCertificate"
        label="Passenger Ufulumuge Certificate"
      >
        <v-radio label="Have" :value="true"></v-radio>
        <v-radio label="Don't Have" :value="false"></v-radio>
      </v-radio-group>

      <v-radio-group
        v-model="salaamatheeCertificate"
        label="Salaamathee Certificate"
      >
        <v-radio label="Have" :value="true"></v-radio>
        <v-radio label="Don't Have" :value="false"></v-radio>
      </v-radio-group>

      <v-text-field
        outlined
        dense
        label="Salaamathee Certificate Expiry Date"
        placeholder="DD/MM/YYYY"
        :rules="[dateValidator]"
        v-model="salaamatheeCertificateExpiryDate"
      ></v-text-field>

      <v-text-field
        outlined
        dense
        label="Annual Sticker Date"
        placeholder="DD/MM/YYYY"
        :rules="[dateValidator]"
        v-model="annualSticker"
      ></v-text-field>

      <v-text-field
        outlined
        dense
        label="Insurance Sticker Date"
        placeholder="DD/MM/YYYY"
        :rules="[dateValidator]"
        v-model="insuranceSticker"
      ></v-text-field>

      <v-text-field
        outlined
        dense
        label="Sea Worthiness Sticker Date"
        placeholder="DD/MM/YYYY"
        :rules="[dateValidator]"
        v-model="seaworthinessSticker"
      ></v-text-field>

      <v-select
        clearable
        dense
        multiple
        outlined
        class="tw-text-xs"
        :items="officers"
        :item-text="'service_number'"
        :item-value="'id'"
        label="Officers"
        :rules="[required]"
        v-model="selectedOfficers"
      >
      </v-select>

      <v-textarea dense outlined label="Notes" v-model="notes"></v-textarea>

      <v-btn
        elevation="2"
        color="primary"
        class="tw-my-2"
        width="100%"
        @click="validate()"
      >
        <span class="tw-text-white">Submit</span>
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import api from "../../plugins/request";
export default {
  data() {
    return {
      valid: false,
      officers: [],
      typeOfVessel: ["Dingee", "Launch", "Dhoani", "Boat"],
      // Form variables
      mobile: "",
      currentAddress: "",
      registerNumber: "",
      registeredIsland: "",
      captain: "",
      crew: "",
      boatBuildersName: "",
      selectedVesselType: "",
      size: "",
      passengerUfulumugeCertificate: "",
      salaamatheeCertificate: null,
      salaamatheeCertificateExpiryDate: "",
      annualSticker: "",
      insuranceSticker: "",
      seaworthinessSticker: "",
      selectedOfficers: "",
      notes: "",
      //
      rules: {
        action: [(v) => !!v || "Action is required"],
      },
    };
  },

  methods: {
    async fetchOfficers() {
      const res = await api.post("/api/v1/client?task=officer-info", {}, false);
      if (res.status == 200) {
        this.officers = res.data.data;
      }
    },

    validate() {
      this.$refs.form.validate();
      if (this.valid) {
        this.$emit("form", {});
      }
    },

    required(value) {
      if (value instanceof Array && value.length == 0) {
        return "Required.";
      }
      return !!value || "Required.";
    },

    dateValidator(value) {
      const reg = new RegExp(
        /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g
      );
      if (!value) {
        return true;
      }
      return reg.test(value) || "Invalid Date Format";
    },
  },

  created() {
    this.fetchOfficers();
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
