var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Current Address"},model:{value:(_vm.currentAddress),callback:function ($$v) {_vm.currentAddress=$$v},expression:"currentAddress"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Mobile"},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"License No"},model:{value:(_vm.licenseNo),callback:function ($$v) {_vm.licenseNo=$$v},expression:"licenseNo"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Vehicle No (A0A1234)","placeholder":"FORMAT A0A1234","rules":[_vm.required]},model:{value:(_vm.vehicleNo),callback:function ($$v) {_vm.vehicleNo=$$v},expression:"vehicleNo"}}),_c('v-autocomplete',{staticClass:"tw-text-xs reasons tw-text-right tw-RTl",attrs:{"clearable":"","dense":"","multiple":"","outlined":"","menu-props":{
          closeOnClick: true,
          closeOnContentClick: true,
        },"items":_vm.reason,"item-text":"note","label":"Reason to Stop","rules":[_vm.required]},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-list-item-content',[_c('div',{staticClass:"dhivehi tw-flex tw-justify-end tw-break-all"},[_c('p',{staticClass:"tw-text-sm tw-text-right",domProps:{"textContent":_vm._s(`${item.note}`)}})])])]}}]),model:{value:(_vm.selectedReason),callback:function ($$v) {_vm.selectedReason=$$v},expression:"selectedReason"}}),_c('v-combobox',{staticClass:"tw-text-xs",attrs:{"clearable":"","dense":"","multiple":"","outlined":"","items":_vm.actions,"menu-props":{
          closeOnClick: true,
          closeOnContentClick: true,
        },"label":"Action Taken","rules":[_vm.required]},model:{value:(_vm.selectedActions),callback:function ($$v) {_vm.selectedActions=$$v},expression:"selectedActions"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Location","prefix":"SH. MILANDHOO/","rules":[_vm.required]},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Annual Sticker Date","placeholder":"MM/YYYY","rules":[_vm.annualSickerValidator]},model:{value:(_vm.annualSticker),callback:function ($$v) {_vm.annualSticker=$$v},expression:"annualSticker"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Insurance Sticker Date","placeholder":"DD/MM/YYYY","rules":[_vm.dateValidator]},model:{value:(_vm.insuranceSticker),callback:function ($$v) {_vm.insuranceSticker=$$v},expression:"insuranceSticker"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Roadworthiness Sticker Date","placeholder":"DD/MM/YYYY","rules":[_vm.dateValidator]},model:{value:(_vm.roadworthinessSticker),callback:function ($$v) {_vm.roadworthinessSticker=$$v},expression:"roadworthinessSticker"}}),_c('v-select',{staticClass:"tw-text-xs",attrs:{"clearable":"","dense":"","multiple":"","outlined":"","items":_vm.officers,"item-text":['service_number'],"item-value":'id',"label":"Officers","rules":[_vm.required],"menu-props":{
          closeOnClick: true,
          closeOnContentClick: true,
        }},model:{value:(_vm.selectedOfficers),callback:function ($$v) {_vm.selectedOfficers=$$v},expression:"selectedOfficers"}}),_c('v-textarea',{attrs:{"dense":"","outlined":"","label":"Notes"},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}}),_c('v-btn',{staticClass:"tw-my-2",attrs:{"elevation":"2","color":"primary","width":"100%"},on:{"click":function($event){return _vm.validate()}}},[_c('span',{staticClass:"tw-text-white"},[_vm._v("Submit")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }