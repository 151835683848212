var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-m-10"},[_c('h1',{staticClass:"tw-text-2xl tw-text-appColor tw-font-semibold"},[_vm._v("Officers")]),_c('v-divider'),_c('v-simple-table',{staticClass:"tw-my-6",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Officer Name")]),_c('th',{staticClass:"text-left"},[_vm._v("Service Number")]),_c('th',{staticClass:"text-left"},[_vm._v("Ban")]),_c('th',{staticClass:"text-left"},[_vm._v("Actions")])])]),_c('tbody',_vm._l((_vm.officers),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.officer_name))]),_c('td',[_vm._v(_vm._s(item.service_number))]),_c('td',[_c('v-icon',{staticClass:"tw-mx-2",attrs:{"color":item.is_user_ban == false ? 'green' : 'red'},domProps:{"textContent":_vm._s(
                  item.is_user_ban == false
                    ? 'mdi-account'
                    : 'mdi-account-cancel'
                )},on:{"click":function($event){return _vm.ban(item.id, item.is_user_ban)}}})],1),_c('td',[_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s('mdi-chart-bar')}}),_c('router-link',{attrs:{"to":{
                  name: 'EditOfficer',
                  params: {
                    id: item.id,
                    name: item.officer_name,
                    service: item.service_number,
                    username: item.username,
                    ban: item.is_user_ban,
                  },
                }}},[_c('v-icon',{staticClass:"tw-mx-2",attrs:{"color":"green"},domProps:{"textContent":_vm._s('mdi-application-edit')}})],1)],1)])}),0)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }