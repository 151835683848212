<template>
  <v-app style="font-family: 'Roboto', sans-serif">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  name: "App",

  methods: {
    async getRefeshToken() {
      const res = await axios.post(
        "/api/v1/auth?task=refresh-token",
        {},
        { withCredentials: true }
      );
      if (res.data.status) {
        this.$store.commit("setUserAccessToken", res.data.data);
      }
    },
  },

  async created() {
    setInterval(() => {
      this.getRefeshToken();
    }, 600000);
  },

  async beforeCreate() {
    const res = await axios.post(
      "/api/v1/auth?task=refresh-token",
      {},
      { withCredentials: true }
    );
    if (res.data.status) {
      this.$store.commit("setUserAccessToken", res.data.data);
    }
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

@font-face {
  font-family: "Faruma";
  src: local("Faruma"), url(../src/assets/Faruma.ttf) format("truetype");
}

.dhivehi {
  font-family: "Faruma";
}
</style>
