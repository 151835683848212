<template>
  <div>
    <v-form v-model="valid" ref="form">
      <v-text-field
        outlined
        dense
        label="Current Address"
        v-model="currentAddress"
      ></v-text-field>

      <v-text-field
        outlined
        dense
        label="Mobile"
        v-model="mobile"
      ></v-text-field>

      <v-text-field
        outlined
        dense
        label="License No"
        v-model="licenseNo"
      ></v-text-field>

      <v-text-field
        outlined
        dense
        label="Vehicle No (A0A1234)"
        placeholder="FORMAT A0A1234"
        v-model="vehicleNo"
        :rules="[required]"
      ></v-text-field>

      <v-autocomplete
        clearable
        dense
        multiple
        outlined
        :menu-props="{
          closeOnClick: true,
          closeOnContentClick: true,
        }"
        class="tw-text-xs reasons tw-text-right tw-RTl"
        :items="reason"
        item-text="note"
        label="Reason to Stop"
        v-model="selectedReason"
        :rules="[required]"
      >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <div class="dhivehi tw-flex tw-justify-end tw-break-all">
              <p v-text="`${item.note}`" class="tw-text-sm tw-text-right"></p>
            </div>
          </v-list-item-content>
        </template>
      </v-autocomplete>

      <v-combobox
        clearable
        dense
        multiple
        outlined
        class="tw-text-xs"
        :items="actions"
        :menu-props="{
          closeOnClick: true,
          closeOnContentClick: true,
        }"
        label="Action Taken"
        v-model="selectedActions"
        :rules="[required]"
      ></v-combobox>

      <v-text-field
        outlined
        dense
        label="Location"
        prefix="SH. MILANDHOO/"
        :rules="[required]"
        v-model="location"
      ></v-text-field>

      <v-text-field
        outlined
        dense
        label="Annual Sticker Date"
        placeholder="MM/YYYY"
        :rules="[annualSickerValidator]"
        v-model="annualSticker"
      ></v-text-field>

      <v-text-field
        outlined
        dense
        label="Insurance Sticker Date"
        placeholder="DD/MM/YYYY"
        :rules="[dateValidator]"
        v-model="insuranceSticker"
      ></v-text-field>

      <v-text-field
        outlined
        dense
        label="Roadworthiness Sticker Date"
        placeholder="DD/MM/YYYY"
        :rules="[dateValidator]"
        v-model="roadworthinessSticker"
      ></v-text-field>

      <v-select
        clearable
        dense
        multiple
        outlined
        class="tw-text-xs"
        :items="officers"
        :item-text="['service_number']"
        :item-value="'id'"
        label="Officers"
        :rules="[required]"
        :menu-props="{
          closeOnClick: true,
          closeOnContentClick: true,
        }"
        v-model="selectedOfficers"
      >
      </v-select>

      <v-textarea dense outlined label="Notes" v-model="notes"></v-textarea>
      <v-btn
        elevation="2"
        color="primary"
        class="tw-my-2"
        width="100%"
        @click="validate()"
      >
        <span class="tw-text-white">Submit</span>
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import api from "../../plugins/request";
export default {
  data() {
    return {
      valid: false,
      officers: [],
      actions: [
        "ނަސޭހަތްތެރިވެވިފައި",
        "މައުލޫމާތައްޓަކައި ނޯޓްކުރެވިފައި",
        "ހުރިހާކަމެއް ފުރިހަމަ",
        "ޖޫރިމަނާ ކުރެވިފައި",
        "ޓޯކުރެވިފައި",
      ],
      stickers: ["Not Expired", "Expired", "Don't Have"],
      reason: [
        {
          note: "ވީސީޕީ",
          id: "1104",
        },
        {
          note: "އެގިންތިއެއްގެ އުޅަނދު ދުއްވުމުގެ ލައިސްންސް ނެތި ދުއްވުން ނުވަތަ ބޭނުންކުރުން",
          id: "1103",
        },
        {
          note: "މުވާސަލާތީ ވަޞީލަތެއް ބޭނުންކުރަމުން ދުއްވުން",
          id: "1904",
        },
        {
          note: "ދުއްފައިދާ އުޅަނދެއްގައި ދުންފަތުގެ އިސްތިޢުމާލުކުރުން",
          id: "1903",
        },
        {
          note: "ލައިސަންސް ހިފަހައްޓާފައިވަނިކޮށް ނުވަތަ ވަގުތީ ލައިސަންސްނެތި ދުއްވުން ނުވަތަ ބޭނުންކުރުން",
          id: "1104",
        },
        {
          note: "ދެފުރޮޅުލީ އިންޖީނުލީ އުޅަނދެއްގައި ގަވާޢިދާ ޚިލާފަށް މުދާ އުފުލުން",
          id: "1502",
        },
        {
          note: "ޤާނޫނުގައި ލާޒިމްކޮށްފައިވާ އަލި ނެތި ދުއްވުން",
          id: "2008",
        },
        {
          note: "އުޅަނދުގެ ފަހަތުން ބޭރަށް ނުކުމެފައި ހުންނަ މުދަލުގެ ކޮޅުގައި ދިދަ ނުވަތަ ބައްތި ހަރުނުކޮށް ދުއްވުން",
          id: "1511",
        },
        {
          note: "ރަޖިސްޓަރީ ނަންބަރު ބޯޑު ހަރުނުކޮށް ނުވަތަ އޮޅުންނުފިލާވަރަށް ހަލާކުވެފައި ވަނިކޮށް އުޅަނދު ދުއްވުން ނުވަތަ ބޭނުންކުރުން",
          id: "1403",
        },
        {
          note: "ރޯޑްވާދިނަސް މިންގަޑަށް ނުފެތޭ އުޅަނދެއް ދުއްވުން ނުވަތަ ބޭނުންކުރުން",
          id: "1200",
        },
        {
          note: "ރަޖިސްޓަރީނުކޮށް އެއްގަމު އުޅަނދު ދުއްވުން ނުވަތަ ބޭނުންކުރުން",
          id: "1801",
        },
        {
          note: "10 އަހަރު ނުފުރޭ ކުޑަކުދިންގެ އިތުރުން ޑަބަލްޝީޓްކޮށްގެން ބައިސްކަލް ދުއްވުން",
          id: "1608",
        },
        {
          note: "އަހަރީފީ މުއްދަތު ހަމަވެފައިވަނިކޮށް އުޅަނދު ދުއްވުން ނުވަތަ ބޭނުންކުރުން",
          id: "1204",
        },
        {
          note: "އަހަރީފީ މުއްދަތު ހަމަވެ 6 މަސްދުވަހަށްވުރެ އިތުރުވެފައިވާ އުޅަނދެއް ދުއްވުން ނުވަތަ ބޭނުންކުރުން",
          id: "1203",
        },
        {
          note: "ތަރޑް ޕާޓީ އިންޝުއަރެންސްނުކޮށް ނުވަތަ ސްޓިކަރނެތި އުޅަނދު ދުއްވުން ނުވަތަ ބޭނުންކުރުން",
          id: "1202",
        },
        {
          note: "ރޯޑްވާދިނަސް ނުހައްދާ ނުވަތަ މުއްދަތު ހަމަވެފައިވަނިކޮށް ނުވަތަ ސްޓިކަރ ނެތި ދުއްވުން ނުވަތަ ބޭނުންކުރުން",
          id: "1201",
        },
        {
          note: "އެއްގަމު އުޅަނދެއްގައި މީހުން އުފުލުމަށް ކަނޑައަޅާފައިވާ ޢަދަދަށްވުރެ އިރުތަށް މީހުން އުފުލުން",
          id: "1606",
        },
        {
          note: "ހެޑްސެޓް އަޅައިގެން ނުވަތަ ވީޑިޔޯބަލަމުން ދުއްވުން",
          id: "1901",
        },
        {
          note: "ނުރައްކާތެރިކޮށް ނުވަތަ ފަރުވާކުޑަކޮށް އެއްގަމު އުޅަނދު ދުއްވައިގެން މީހެއްގެ ފުރާނައަށް ގެއްލުމެއް ލިބުން",
          id: "2016",
        },
        {
          note: "ނުރައްކާތެރިކޮށް ނުވަތަ ފަރުވާކުޑަކޮށް އެއްގަމު އުޅަނދު ދުއްވުމާއި، މީހެއްގެ ހަށިގަނޑަށް ނުވަތަ ފުރާނައަށް ގެއްލުމެއް ލިބިދާފަދަ ގޮތަކަށް ދުއްވުން",
          id: "2015",
        },
        {
          note: "މަގަށް ދަތިވާގޮތަށް އެއްހަމައެއްގައި ދުއްވުން",
          id: "2013",
        },
        {
          note: "ކަނޑައެޅިފައިވާ ދުވެއްޔަށްވުރެ މަޑުން ދުއްވުން",
          id: "2012",
        },
        {
          note: "ޤަވާއިދާއި ޚިލާފު ހެލްމެޓް ބޭނުންކުރުން",
          id: "2011",
        },
        {
          note: "ދަގަނޑު ފުރޮޅު ނުވަތަ ދަގަނޑު ޗޭނު ލީ ފުރޮޅު އަޅުވައިގެން ދުއްވުން",
          id: "2010",
        },
        {
          note: "އިމަރޖެންސީ އުޅާނދުގައި ބޭނުންކުރާ އަލިތަކާއި އަޑުތައް، އެހެން އުޅަނދުގައި ބޭނުންކުރުން",
          id: "2009",
        },
        {
          note: "ކަނޑައަޅާ ދުވެއްޔަށްވުރެ ބާރަށް ދުއްވުން",
          id: "2007",
        },
        {
          note: "ހެލްމެޓް ނާޅާ ސައިކަލް ދުއްވުން",
          id: "2006",
        },
        {
          note: "އިސްތިސްނާކުރެވޭ އުޅަނދަކަށް ހުރަސް އެޅުން",
          id: "2005",
        },
        {
          note: "އުޅަނދު ދުއްވިއިރު ސީޓް ބެލްޓް ނާޅާތިބުން",
          id: "2004",
        },
        {
          note: "ވިޔަފާރީ އުޞޫލުން ބޭނުންކުރާ އުޅަނދެއްގައި އެތެރެ ނުފެންނަ ބިއްލޫރި ހަރުކޮށްގެން ދުއްވުން",
          id: "2003",
        },
        {
          note: "އިމަޖަންސީ ބަރޮގޮނު އަޅާފައިދާ އެއްގަމު އުޅަނދަކަށް ހުރަސްއެޅުން",
          id: "2002",
        },
        {
          note: "އުޅަނދުގައި ތަޅު އަޅުވާފައިހުއްޓާ ހަލާކުރުން ނުވަތަ ގެއްލުންދިނުން",
          id: "2001",
        },
        {
          note: "ބާރަށް ލަވަޖެހުން ނުވަތަ އަޑުގަދަކުރާ ބޭނުން ކުރަމުން ދުއްވުން",
          id: "1902",
        },
        {
          note: "ވިޔަފާރި އުޞޫލުން ބޭނުންކުރާ އުޅަނދެއްގައި ޑޮމެއިން ބޭނުންކުރުން",
          id: "1813",
        },
        {
          note: "ސުން ބޯޑު ޖަހައިގެން އުޅަނދު އެއްތަނުން އަނެއް ތަނަށް ގެންދާއިރުމ މީހުންނާއި މުދާ އުފުލުން",
          id: "1811",
        },
        {
          note: "އެޅަނދަކަށް ދީފައިވާ ނަންބަރު ބޯޑު ނޫން އެހެން ބޯޑެއް ހަރުކުރުމާއި، ރަޖިސްޓްރީނުކުރާ އުޅނަދެއްގައި ރަޖިސްޓަރީ ނަންބަރު ބޯޑެއް ހަރުކުރުމާއި އަދި ހުއްދަނެތި އުޅާނދުގެ އިންޖީނު ނުވަތަ ޗެސިސް ނަންބަރަށް ގެނެސްފައިވާކަމަށް ތަހުޤީޤަށް ސާބިތުވުން",
          id: "1810",
        },
        {
          note: "ހުއްދަނެތި އުޅަނދުގެ އިންޖީނު ނުވަތަ ޗެސިސް ނަންބަރަށް ބަދަލުގެސްގެން ދުއްވުން ނުވަތަ ބޭނުންކުރުން",
          id: "1809",
        },
        {
          note: "ރަޖިސްޓަރީނުކުރާ އުޅަނދެއްގައި ރަޖިސްޓްރީ ނަންބަރު ބޯޑެއް ހަރުކޮށްގެން ދުއްވުން ނުވަތަ ބޭނުންކުރުން",
          id: "1808",
        },
        {
          note: "ވިއްކާފައި ނުވަތަ ދީފައިވާ އުޅަނދެއްގެ ރަޖިސްޓަރީ ބަދަލުނުކޮށް ދުއްވުން ނުވަތަ ބޭނުންކުރުން (ވެރިފަރާތް)",
          id: "1807",
        },
        {
          note: "ރަޖިސްޓްރީނުކޮށް ނުވަތަ ބާތިލްކޮށްފައިވަނިކޮށް ހުއްދަނުހޯދައި ދުއްވުން",
          id: "1806",
        },
        {
          note: "ރަޖިސްޓަރީކޮށްފައިވާ ޒޯނުނޫން އެހެން ޒޯނެއްގައި، ހުއްދަ ނުހޯދައި ދުއްވުން ނުވަތަ ބޭނުންކުރުން",
          id: "1805",
        },
        {
          note: "ރަޖިސްޓަރީ ބާޠިލްކޮށްފައިވާ އުޅަނދެއް އަލުން ރަޖިސްޓަރީނުކޮށް ދުއްވުން ނުވަތަ ބޭނުންކުރުން (ވެރިފަރާތް)",
          id: "1803",
        },
        {
          note: "ރަޖިސްޓަރީ ބާޠިލްކޮށްފައިވާ އުޅަނދެއް އަލުން ރަޖިސްޓަރީނުކޮށް ދުއްވުން ނުވަތަ ބޭނުންކުރުން (ދުއްވިފަރާތް)",
          id: "1802",
        },
        {
          note: "އެކްސިޑެންޓްވުމުން އަންގަންޖެހޭ ފަރާތްތަކަށް ނާންގާ ދިޔުން",
          id: "1701",
        },
        {
          note: "އެކްސިޑެންޓްވުމުން ހުއްދަ ނުހޯދައި ސީން ރޫޅާލުން",
          id: "1700",
        },
        {
          note: "މީހުން އުފުލުމަށް ރަޖިސްޓަރީކޮށްފައިވާ އުޅަނދެއްގައި މުދާ އުފުލުން",
          id: "1617",
        },
        {
          note: "މުދާ އުފުލުމަށް ރަޖިސްޓަރީކޮށްފައިވާ އުޅަނދެއްގައި މީހުން އުފުލުން",
          id: "1616",
        },
        {
          note: "މީހުން އެރުމަށް ފަރުމާކޮށްފައިވާ ޢަދަދަށްވުރެ އިތުރަށް މީހުން އަރައިގެން ބައިސްކަލު ދުއްވުން",
          id: "1615",
        },
        {
          note: "6 އަހަރު ނުފުރޭ ކުޑަކުއްޖަކު ބައިސްކަލުގައި ހަރުކޮށްފައިވާ ގޮނޑީގައި ބައިންދައިގެން ނޫންގޮތަކަށް އުފުލުން",
          id: "1614",
        },
        {
          note: "ކަފިހިހުރަހުން މަގުހުރަސްކުރާ މީހާއަށް ޖާގަނުދީ އުޅަނދު ދުއްވުން",
          id: "1613",
        },
        {
          note: "ގަވާއިދާ ޚިލާފަށް ޕާކުކުރުން",
          id: "1611",
        },
        {
          note: "ދުއްވަން ކަނޑައަޅާފައިވާ ލޭން ނޫން އެހެން ލޭންގައި ދުއްވުން",
          id: "1610",
        },
        {
          note: "ދުއްވުންމަނާ ގަޑީގައި ހުއްދަ ނުހޯދައި ދުއްވުން",
          id: "1609",
        },
        {
          note: "ސޭފްޓީ ކަމަރު ނުބަނދެ ކުޑަކުދިން ގޮވައިގެންސައިކަލް ދުއްވުން",
          id: "1607",
        },
        {
          note: "މަގުބޭނުންކުރާ ފަރާތްތަކަށް ދަތިވާގޮތަށް އުޅަނދު ހުއްޓުވާފައި އޮތުން ނުވަތަ ބޭއްވުން",
          id: "1605",
        },
        {
          note: "ދުވާރުމަތީގައި އެއްގަމު އުޅަނދު ދުއްވުން ނުވަތަ ޕާކުކުރުން",
          id: "1604",
        },
        {
          note: "ޓްރެފިކް ބައްތިއާއި ޚިލާފަށް ދުއްވުން",
          id: "1603",
        },
        {
          note: "ޓްރެފިކް ނިޝާންތަކާއި ޚިލާފްވުން",
          id: "1602",
        },
        {
          note: "ވަންވޭއާއި ޚިލާފަށް ދުއްވުން",
          id: "1601",
        },
        {
          note: "ކަނޑައަޅާފައިވާ އަގަށްވުރެ ބޮޑު އަގުގައި މުދަލާއި ތަކެތި އުފުލުން",
          id: "1520",
        },
        {
          note: "ހުއްދަނެތް މަގަކަށް ތެޔޮ ބަރުކުރުން ނުވަތަ ތެޔޮއެޅުން",
          id: "1519",
        },
        {
          note: "މަނާ ސަރަޙައްދެއްގައި ނުވަތަ ވަގުތެއްގައި ތެޔޮބަރުކުރުން ނުވަތަ ތެޔޮއެޅުން",
          id: "1518",
        },
        {
          note: "އަލިފާން ހިފާ ނުވަތަ ގޮވާ ޒާތުގެ ތަކެތި އުފުލާ އުޅަނދުގައި 2 މީހުންނަށްވުރެ އިތުރަށް މީހުން އެތުވުން",
          id: "1517",
        },
        {
          note: "އުޅަނދުގައި ކުނި އުފުލާއިރު ފަހަތު ފޮށިގަނޑުގައި މީހުން ތިބުން",
          id: "1516",
        },
        {
          note: "އުޅަނދުފައި ފިލާ ޖައްސައިގެން، ފޮށިގަނޑުތެރެއަށް ކުނި އަޅައިގެން ދުއްވުން",
          id: "1515",
        },
        {
          note: "ޓްރޯލީ ދަމަން ހުންނަ އަތްގަނޑަށްވުރެ މައްޗަށް މުދާ އަރުވައިގެން ދުއްވުން",
          id: "1514",
        },
        {
          note: "މުދާ އުފުލާއިރު އުޅަނދުގެ ކުރިއަށް މުދާ ނެރެފައި ބެހެއްޓުން ނުވަތަ އުޅަނދުގެ ފަހަތަށް ފޮށިގަނޑުގެ 3/1 އަށްވުރެ ބޭރަށް މުދާ ނެރެފައި ބެހެއްޓުން",
          id: "1510",
        },
        {
          note: "އުޅަނދުގެ ކުރިއާއި ފަހަތް އަދި ދިއްލަންޖެހޭ ބައްތިތަކާއި ނަންބަރު ޕްލޭޓް ނުފެންނަގޮތަށް މުދާ އަރުވައިގެން ދުއްވުން",
          id: "1509",
        },
        {
          note: "ސައިކަލުގައި ޤަވާއިދާ ޚިލާފަށް މުދާ އުފުލުން",
          id: "1508",
        },
        {
          note: "މީހުންނަށް އުނދަގޫވާގޮތަށް ނުވަތަ މަގުމައްޗަށް ކުނިއެޅޭގޮތަށް، ކުނިއަރުވައިގެން ދުއްވުން",
          id: "1507",
        },
        {
          note: "ހުއްދަ ނުހޯދައި، ނިޝާން ނުބަހައްޓައި މަގުމަތީގައި ނުވަތަ ދުވާރުމަތީގައި މަސައްކަތްކުރުން",
          id: "1506",
        },
        {
          note: "ހިނގާމީހުންނަށް ދަތިވާގޮތަށް ދުވާރުމަތީގައި ނުވަތަ ދުއްވާ އެއްޗިއްސަށް ދަތިވާގޮތަށް މަގުމަތީގައި މުދަލާއި ތަކެތި ބެހެއްޓުން",
          id: "1505",
        },
        {
          note: "މުދާ އުފުލުމަށް ރަޖިސްޓަރީކޮށްފައިވާ އުޅާނދުގައި އުފުލޭ ބަރުދަން ނުޖަހައި ދުއްވުން ނުވަތަ ބޭނުންކުރުން",
          id: "1504",
        },
        {
          note: "ބައިސްކަލުގައި ގަވާއިދާ ޚިލާފަށް މުދާ އުފުލުން",
          id: "1503",
        },
        {
          note: "މުދާ އުފުލާ އުޅަނދަކުން ޤާނޫނާއި ޚިލާފަށް މުދާއުފުލުން",
          id: "1501",
        },
        {
          note: "ޓްރާންސްޕޯރޓް ޤާނޫނާއި ޚިލާފަށް ނަންބަރު ނުވަތަ ނިޝާނަށް ބަދަލުގެނެސްގެން ބޭނުން ކުރަން ދިނުން",
          id: "1408",
        },
        {
          note: "މިނިސްޓްރީން ކަނޑައަޅާފައިވާ ނަމޫނާއާއި ޚިލާފަށް އުޅަނދުގައި ނަންބަރު ބޯޑު ހަރުކުރުން",
          id: "1407",
        },
        {
          note: "އުޅަނދަކަށް ދީފައިވާ ނަންބަރު ބޯޑު ނޫން އެހެން ބޯޑެއް އުޅާނދުގައި ހަރުކޮށްގެން ދުއްވުން ނުވަތަ ބޭއްވުން",
          id: "1406",
        },
        {
          note: "ރަޖިސްޓަރީ ކޮށްފައިވާ އުޅަނދު ނޫން އެހެން އުޅާނދެއްގައި ޑޮމެއިންބޭނުންކުރުން",
          id: "1405",
        },
        {
          note: "ރަޖިސްޓަރީ ނަންބަރު ބޯޑު ނުފެންނަގޮތަށް ނިވާކޮށްފައި ބޭއްވުން",
          id: "1404",
        },
        {
          note: "ޑޮމެއިން ހުއްދަ އުޅަނދުގައި ނެތި ދުއްވުން ނުވަތަ ބޭނުންކުރުން",
          id: "1402",
        },
        {
          note: "ނަންބަރު ބޯޑު ކަރުދާހުން ހަދައިގެން ދުއްވުން ނުވަތަ ބޭނުންކުރުން",
          id: "1401",
        },
        {
          note: "ޤަވާއިދާ ޚިލާފަށް ބަހުގެ ޚިދުމަތްދިނުން",
          id: "1313",
        },
        {
          note: "މޮބައިލް އެޕްލިކޭޝަންގައި ރަޖިސްޓަރީނުކޮށް ޕްރައިވެޓް ހަޔަރގެ ޚިދުމަތްދިނުން",
          id: "1312",
        },
        {
          note: "ހުއްދަ ނެތް މީހަކު ޕްރައިވެޓް ހަޔަރގެ ޚިދުމަތްދިނުން",
          id: "1311",
        },
        {
          note: "ހުއްދަ ނެތް އުޅަނދަކުން ޕްރައިވެޓް ހަޔަރގެ ޚިދުމަތްދިނުން",
          id: "1310",
        },
        {
          note: "ޓެކްސީ ދަތުރެއްގެ ތެރެއިން އިތުރު ދަރުރެއް ނެގުން",
          id: "1309",
        },
        {
          note: "ހުއްދަ ނެތް އުޅަނދަކުން ޓެކްސީގެ ޚިދުމަތްދިނުން",
          id: "1308",
        },
        {
          note: "ޓެކްސީ ޚިދުމަތްދިނުމަށް ކަނޑައަޅާފައިވާ ޢަދަދަށްވުރެ އިތުރަށް އަގު ނެގުން",
          id: "1307",
        },
        {
          note: "ޓެކްސީއެއްގައި ހުންނަންޖެހޭ ހުއްދަތައްނެތި ޓެކްސީގެ ޚިދުމަތްދިނުން",
          id: "1306",
        },
        {
          note: "ޓެކްސީ ސެންޓަރެއް މެދުވެރި ނުކޮށް ނުވަތަ ޓެކްސީނޫން އުޅަދަކުން ޚިދުމަތް ދިނުން",
          id: "1305",
        },
        {
          note: "ހުއްދަ ނެތް މީހަކު ޓެކްސީގެ ޚިދުމަތްދިނުން ނުވަތަ ދުއްވުން",
          id: "1304",
        },
        {
          note: "ޓެކްސީ ޑްރައިވަރުގެ ލައިސަންސްބާތިލް ކޮށްފައިވަނިކޮށް ޓެކްސޯ ޚިދުމަތް ދިނުން",
          id: "1303",
        },
        {
          note: "ޓެކްސީއެއްގައި ހުންނަންޖެހޭ ސިފަތަކާއި ވަޞީލަތްތައް ނެތި ޓެކްސީ ޚިދުމަތްދިނުން",
          id: "1302",
        },
        {
          note: "ޤަވާއިދާ ޚިލާފަށް ޓެކްސީގެ ޚިދުމަތް ދިނުން",
          id: "1301",
        },
        {
          note: "ލައިސަންސްނެތި ދުއްވި ނުވަތަ އޮޕަރޭޓްކުރި އުޅަނދުގެ ވެރިފަރާތް",
          id: "1108",
        },
        {
          note: "ލައިސަންސް ގެއްލިފައިވަނިކޮށް ނުވަތަ ހަލާކުވެފައިވަނިކޮށް އުޅަނދު ދުއްވުން ނުވަތަ ބޭނުންކުރުން",
          id: "1107",
        },
        {
          note: "އިންސްޓްރެކްޓަރ ކަމުގެ ހުއްދަ ނެތި ދުއްވަން ދަސްކޮށްދިނުން",
          id: "1105",
        },
        {
          note: "ލައިސްންސް ލިބިފައިވާ މީހާ ދުއްވި އިރު އަތުގައި ލައިސްންސް ނެތި ދުއްވުން",
          id: "1101",
        },
        {
          note: "ދުއްވަން ދަސްކޮށްދޭން ކަނޑައަޅާފައިވާ ދުވެއްޔަށްވުރެ ބާރަށް ދުއްވުން",
          id: "1005",
        },
        {
          note: "ދުއްވަން ދަސްކޮށްދޭން ކަނޑައަޅާފައިވާ ވަގުތުނޫން އެހެން ވަތުތެއްގައި ދުއްވުން",
          id: "1004",
        },
        {
          note: "ދުއްވަން ދަސްކޮށްދޭން ކަނޑައަޅާފައިވާ ސަރަޙައްދުނޫން އެހެން ސަރަޙައްދެއްގައި ދުއްވުން",
          id: "1003",
        },
        {
          note: "ދަސްކުރަންދުއްވާ އުޅަނދުގައި މީހުންނާއި މުދާ އުފުލުން",
          id: "1002",
        },
        {
          note: "ދަސްކުރަންދުއްވާއިރު، މިނިސްޓްރީން ކަނޑައަޅާފައިވާ ނަމޫނާއާއި އެއްގޮތަށް ހަދާފައިވާ ނިޝާންނެތި ދުއްވުން",
          id: "1001",
        },
      ],
      // Form variables
      mobile: "",
      currentAddress: "",
      licenseNo: "",
      vehicleNo: "",
      selectedReason: "",
      selectedActions: "",
      location: "",
      annualSticker: "",
      insuranceSticker: "",
      roadworthinessSticker: "",
      selectedOfficers: "",
      notes: "",
      location_coordinates: "",

      //

      rules: {
        action: [(v) => !!v || "Action is required"],
      },
    };
  },

  methods: {
    async fetchOfficers() {
      const res = await api.post("/api/v1/client?task=officer-info", {}, false);
      if (res.status == 200) {
        const unbanOfficers = [];
        res.data.data.forEach((officer) => {
          if (officer.is_user_ban == false) {
            unbanOfficers.push(officer);
          }
        });
        this.officers = unbanOfficers;
      }
    },

    getPosition() {
      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject)
      );
    },

    async validate() {
      try {
        const position = await this.getPosition();
        this.location_coordinates = [
          position.coords.latitude,
          position.coords.longitude,
        ];

        this.$refs.form.validate();
        if (this.valid) {
          this.$emit("form", {
            form_type: "vehicle_forms",
            mobile: this.mobile,
            currentAddress: this.currentAddress.toUpperCase(),
            licenseNo: this.licenseNo.toUpperCase(),
            vehicle_no: this.vehicleNo.toUpperCase(),
            reason_to_stop: this.selectedReason.toString(),
            action_taken: this.selectedActions.toString(),
            location: this.location,
            location_coordinates: this.location_coordinates,
            annual_sticker: this.annualSticker,
            insurance_sticker: this.insuranceSticker,
            roadworthiness: this.roadworthinessSticker,
            selectedOfficers: this.selectedOfficers,
            notes: this.notes,
          });
        }
      } catch (error) {
        alert(error.message);
      }
    },

    required(value) {
      if (value instanceof Array && value.length == 0) {
        return "Required.";
      }
      return !!value || "Required.";
    },

    annualSickerValidator(value) {
      const reg = new RegExp(/^(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/g);
      if (!value) {
        return true;
      }
      return reg.test(value) || "Invalid Date Format";
    },

    dateValidator(value) {
      const reg = new RegExp(
        /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g
      );
      if (!value) {
        return true;
      }
      return reg.test(value) || "Invalid Date Format";
    },
  },

  created() {
    this.fetchOfficers();
  },
};
</script>

<style>
.dhivehi {
  font-family: "Faruma";
}
.reasons .v-select__selections {
  font-family: "Faruma";
  width: 100%;
  text-align: right !important;
}

#input-75 {
  font-family: "Faruma";
  text-align: right !important;
  direction: RTL;
}

#input-75 .v-select__selections {
  text-align: right !important;
  direction: RTL;
}

#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div.tw-max-w-screen-2xl.sm\:tw-p-10.tw-p-4.tw-mx-auto
  > div
  > div.tw-my-4.tw-p-6.v-card.v-sheet.theme--light
  > div:nth-child(2)
  > form
  > div.v-input.tw-text-xs.reasons.tw-text-right.tw-RTl.v-input--is-label-active.v-input--is-dirty.v-input--is-focused.v-input--dense.theme--light.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined.v-select.v-select--is-menu-active.v-select--is-multi.v-autocomplete.primary--text
  > div
  > div.v-input__slot
  > div.v-select__slot
  > div.v-select__selections {
  flex-direction: row-reverse;
}
</style>
