<template>
  <div>
    <v-form v-model="valid" ref="form">
      <v-text-field
        v-show="!this.isEditAble"
        outlined
        dense
        label="Current Address"
        v-model="currentAddress"
      ></v-text-field>

      <v-text-field
        v-show="!this.isEditAble"
        outlined
        dense
        label="Mobile"
        v-model="mobile"
      ></v-text-field>

      <v-autocomplete
        clearable
        dense
        multiple
        outlined
        :menu-props="{
          closeOnClick: true,
          closeOnContentClick: true,
        }"
        class="tw-text-xs reasons tw-text-right tw-RTl"
        :items="reason"
        item-text="note"
        label="Reason to Stop"
        v-model="selectedReason"
        :rules="[required]"
      >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <div class="dhivehi tw-flex tw-justify-end tw-break-all">
              <p v-text="`${item.note}`" class="tw-text-sm tw-text-right"></p>
            </div>
          </v-list-item-content>
        </template>
      </v-autocomplete>

      <v-select
        clearable
        dense
        multiple
        outlined
        class="tw-text-xs"
        :items="actions"
        label="Action Taken"
        v-model="selectedActions"
        :rules="[required]"
        :menu-props="{
          closeOnClick: true,
          closeOnContentClick: true,
        }"
      ></v-select>

      <label
        class="tw-w-full tw-text-xs tw-text-appColor tw-text-right tw-justify-end tw-flex tw-pb-0.5"
      >
        މިބައި ފުރިހަމަ ކުރާނީ ޖޫރިމަނާ ކުރާނަމަ*</label
      >
      <v-select
        clearable
        dense
        multiple
        outlined
        class="tw-text-xs"
        :items="fine"
        label="Fine"
        v-model="selectedFine"
        :menu-props="{
          closeOnClick: true,
          closeOnContentClick: true,
        }"
      ></v-select>

      <v-text-field
        outlined
        dense
        label="Location"
        prefix="SH. MILANDHOO/"
        :rules="[required]"
        v-model="location"
      ></v-text-field>

      <v-select
        v-show="!this.isEditAble"
        clearable
        dense
        multiple
        outlined
        class="tw-text-xs"
        :items="officers"
        :item-text="'service_number'"
        :item-value="'id'"
        label="Officers"
        :rules="[required]"
        :menu-props="{
          closeOnClick: true,
          closeOnContentClick: true,
        }"
        v-model="selectedOfficers"
      >
      </v-select>

      <v-textarea dense outlined label="Notes" v-model="notes"></v-textarea>
      <v-btn
        elevation="2"
        color="primary"
        class="tw-my-2"
        width="100%"
        @click="validate()"
      >
        <span class="tw-text-white">{{
          !this.isEditAble ? "SUBMIT" : "UPDATE"
        }}</span>
      </v-btn>
    </v-form>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import api from "../../plugins/request";

export default {
  props: {
    personData: { default: null },
    isEditAble: { type: Boolean, required: true },
  },

  data() {
    return {
      valid: false,
      overlay: false,
      officers: [],
      actions: [
        "ބޮޑީޗެކްކުރުން",
        "މައުލޫމާތައްޓަކައި ނޯޓްކުރެވިފައި",
        "ނަސޭހަތްތެރިވުން",
        "ސަރަހައްދުން ފޮނުވާލުން",
        "ފުލުހުންގެ ބެލުމުގެ ދަށަށް ގެނައުން",
        "ޖޫރިމަނާކުރުން",
        "ލައިސަންސް ހިފެހެއްޓުން",
        "ޑޯޔާޑަށް ގެންދިއުން",
      ],
      fine: ["MASK", "SMOKING", "KUNI", "PARKING"],
      reason: [
        {
          note: "ޖިނާއީ އިޖުރާތު ގާނޫނު 38 ގެ (ރ) 1 – އަނިޔާވެރި އަމަލެއް ހުންގުމަށް ގެންގުޅޭ އެއްޗެއްކަމަށް ބެލެވޭ ވަޅިއެއް ނުވަތަ ހަންޖަރެއް ނުވަތަ ތޫނު އެއްޗެއް ނުވަތަ ހަތިޔާރެއް ކަމުގައި ބެލެވިދާނެ އެނޫންވެސް އެއްޗެއް އަތުގައި އޮތްކަން ބެލެވުން",
          id: "1",
        },
        {
          note: "ޖިނާއީ އިޖުރާތު ގާނޫނު 38 ގެ (ރ) 2 – އަތުގައި މަސްތުވާއެއްޗެއް ނުވަތަ ބަނގުރާ ހުރިކމަށް ބެލެވުން",
          id: "2",
        },
        {
          note: "ޖިނާއީ އިޖުރާތު ގާނޫނު 38 ގެ (ރ) 3 – އަތުގައި ވަގަށްނަގާފައިވާ އެއްޗެއް ނުވަތަ މިލްކުވެރިކަން ސާބިތުކޮށްނުދެވޭ އެއްޗެއް ހުރިކަމަށް ބެލެވުން",
          id: "3",
        },
        {
          note: "ޖިނާއީ އިޖުރާތު ގާނޫނު 38 ގެ (ރ) 4 – އަތުގައި ކުއްކޮށްގެން ހޯދާފައިވާ އެއްޗެއް ހުރިކަމަށް ބެލެވުން",
          id: "4",
        },
        {
          note: "ޖިނާއީ އިޖުރާތު ގާނޫނު 38 ގެ (ރ) 5 – ޖިނާއީ ބޮޑުކުށެއްގެ ހެކީގެ ގޮތުގައި ބޭނުންކުރެވިދާނެ އެއްޗެއް އަތުގައި ހުރިކަމަށް ބެލެވުން",
          id: "5",
        },
        {
          note: "ޖިނާއީ އިޖުރާތު ގާނޫނު 39 ގެ (ހ) 1 – ކުށެއް ކުރަމުންދަނިކޮށް ފެނިގެން",
          id: "6",
        },
        {
          note: "ޖިނާއީ އިޖުރާތު ގާނޫނު 39 ގެ (ހ) 2 – ކުށެއް ކޮށްފައިވާކަން އެކަށީގެންވާ ހެކީގެމައްޗައް ބިނާކޮށް ތުހުމަތު ކުރެވޭތީވެ",
          id: "7",
        },
        {
          note: "ޖިނާއީ އިޖުރާތު ގާނޫނު 39 ގެ (ހ) 3 – ކޯޓު އަމުރު ތަންފީޛު ކުރުމަށްޓަކައި އެ އަމުރު ރައްދުވާ ފަރާތް",
          id: "8",
        },
        {
          note: "ޖިނާއީ އިޖުރާތު ގާނޫނު 39 ގެ (ހ) 4 – ފުލުހުން ހިންގަމުންދާ ތަޙްޤީޤެއްގައި އެހީތެރިވެވިދާނެ ފަރާތެއް ކަމަށް ފެނިގެން",
          id: "9",
        },
        {
          note: "ޖިނާއީ އިޖުރާތު ގާނޫނު 40 ގެ (ނ) 1 – ކުށެއް ކޮށްފިކަމަށް އެކަށިގެންވާ ހެކި ލިބިފައިވާތީ",
          id: "10",
        },
        {
          note: "ޖިނާއީ އިޖުރާތު ގާނޫނު 40 ގެ (ނ) 2 – ކުރަމުންދާ ކުށެއް ހުއްޓުވުމަށްޓަކައި، ނުވަތަ އެވަގުތު އެކުށުގެ ޢަމަލު ތަކުރާރު ކުރިޔަ ނުދިނުމަށްޓަކައި، ނުވަތަ ހިންގަމުންދިޔަ ޢަމަލުން އެހެން ކުށެއް ކުރިޔަ ނުދިނުމަށްޓަކައި",
          id: "11",
        },
        {
          note: "ޖިނާއީ އިޖުރާތު ގާނޫނު 40 ގެ (ނ) 3 – އެވަގުތު އެއުޅެނީ ކުށެއް ކުރަން ކަމަށް ބުއްދިއަށް ޤަބޫލުކުރެވޭފަދަ އެކަށިގެންވާ ސަބަބުތަކެއް ނުވަތަ ހެކި ލިބިފައިވާ ޙާލަތުގައި އެކުށް ކުރިޔަ ނުދިނުމަށްޓަކައި",
          id: "12",
        },
        {
          note: "ޖިނާއީ އިޖުރާތު ގާނޫނު 40 ގެ (ނ) 4 – ނަމާއި އުމުރު  ބަޔާންކޮށްދިނުމަށް ޖިނާއީ އިޖުރާތު ގާނޫނުގެ 38 ވަނަ މާއްދާގެ (ޅ) ގެ (1) ވަނަ ނަންބަރުގައި ބަޔާންކުރާ ޙާލަތުގައި ކުރި އަމުރަށް އިންކާރުކުރުމުން، އެ މަޢުލޫމާތު ދެނެގަތުމަށްޓަކައި",
          id: "14",
        },
        {
          note: "ޖިނާއީ އިޖުރާތު ގާނޫނު 40 ގެ (ނ) 5 – ކޯޓަށް ހާޒިރުވުމުގެ އަމުރެއް ނިސްބަތްވެ އެ އަމުރަށް ތަބާނުވުމުން، ކޯޓަށް ހާޒިރުކޮށްދިނުމަށް ކޯޓުންކުރާ އަމުރެއް ތަންފިޛު ކުރުމަށްޓަކައި",
          id: "15",
        },
        {
          note: "ޖިނާއީ އިޖުރާތު ގާނޫނު 40 ގެ (ނ) 6 – ޖިނާއީ ބޮޑު ކުށެއްގެ ހެކި ފޮރުވުމަށް ނުވަތަ ނައްތާލުމަށް މަސައްކަތްކޮށްފާނެކަމަށްޓަކއި",
          id: "16",
        },
        {
          note: "ޖިނާއީ އިޖުރާތު ގާނޫނު 40 ގެ (ނ) 7 – ޤާނޫނީގޮތުން ބަންދުގައި ބެހެއްޓޭނެ ޙާލަތެއްގައި ބަންދުގައި ހުރެ ފިލައިގެން ދިޔަނުދިނުމަށްޓަކައި",
          id: "17",
        },
        {
          note: "ފުލުހުންގެ ބެލުމުގެ ދަށައް ގެނައުން ",
          id: "18",
        },
        {
          note: "ކުއްލިހާލަތު",
          id: "19",
        },
        {
          note: "ކޯޓް އަމުރެއްގެ ދަށުން",
          id: "20",
        },
        {
          note: "69/87 ކަނޑުގައި ދުއްވާ އުޅަނދުތަށް ދުއްވުމާބެހޭ ޤާނޫނު",
          id: "21",
        },
        {
          note: "5/2009 އެއްގަމު ދަތުރުފަތުރުގެ ޤާނޫން",
          id: "22",
        },
        {
          note: "ހުކުމާއި ހަވާލުކުމަށް",
          id: "23",
        },
        {
          note: "ހޯމްމިންސްޓުރީންއެދިގެން ކަރެކްޝަން ސާރވިސް އާއި ހަވާލުކުރުމަށް",
          id: "24",
        },
        {
          note: "ކަރެކްޝް ސާރވިސްއިން އެދިގެން ކަރެކްޝަން ސާރވިސް އާއި  ހަވާލުކުރުމަށް",
          id: "25",
        },
        {
          note: "2010/15 ދުންފަތް ކޮންޓުރޯލްކުރުމާއިބެހޭ ޤާނޫނު",
          id: "26",
        },
      ],

      mobile: "",
      currentAddress: "",
      selectedReason: "",
      selectedActions: "",
      selectedFine: "",
      selectedOfficers: [],
      location: "",
      notes: "",
      location_coordinates: "",
      rules: {
        action: [(v) => !!v || "Action is required"],
      },
    };
  },

  methods: {
    async fetchOfficers() {
      const res = await api.post("/api/v1/client?task=officer-info", {}, false);
      if (res.status == 200) {
        const unbanOfficers = [];
        res.data.data.forEach((officer) => {
          if (officer.is_user_ban == false) {
            unbanOfficers.push(officer);
          }
        });
        this.officers = unbanOfficers;
      }
    },

    getPosition() {
      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject)
      );
    },

    async validate() {
      try {
        if (this.isEditAble) {
          this.overlay = true;
          const position = await this.getPosition();
          this.overlay = false;
          this.location_coordinates = [
            position.coords.latitude,
            position.coords.longitude,
          ];
        }
        this.$refs.form.validate();
        if (this.valid) {
          this.$emit("form", {
            form_type: "person_search_form",
            mobile: this.mobile,
            reason_to_stop: this.selectedReason.toString(),
            currentAddress: this.currentAddress.toUpperCase(),
            selectedActions: this.selectedActions.toString(),
            selectedFine: this.selectedFine.toString(),
            selectedOfficers: this.selectedOfficers,
            location: this.location,
            location_coordinates: this.location_coordinates,
            notes: this.notes,
          });
        }
      } catch (error) {
        this.overlay = false;
        alert(error.message);
      }
    },

    // Edit stop and person form;
    getEditableData() {
      if (this.personData) {
        this.selectedActions = this.personData.action_taken.split(",");
        this.selectedFine = this.personData.fine.split(",");
        this.location = this.personData.location;
        this.location_coordinates = this.personData.location_coordinates;
        this.notes = this.personData.notes;
        this.getOfficer();
      }
    },

    getOfficer() {
      this.personData.officers.forEach((officer) => {
        this.selectedOfficers.push(officer.id);
      });
    },
    //

    required(value) {
      if (value instanceof Array && value.length == 0) {
        return "Required.";
      }
      return !!value || "Required.";
    },
  },

  created() {
    this.fetchOfficers();
    if (this.isEditAble) {
      this.getEditableData();
    }
  },
};
</script>

<style>
.dhivehi {
  font-family: "Faruma";
}

.reasons .v-select__selections {
  font-family: "Faruma";
  width: 100%;
  text-align: right !important;
}
</style>
