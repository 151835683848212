<template>
  <div class="tw-overflow-auto">
    <div class="tw-py-1 tw-my-2 tw-bg-gray-200">
      <h4 class="tw-text-sm tw-font-semibold tw-px-2 tw-uppercase">
        Interaction
      </h4>
    </div>
    <v-list-item-title class="tw-py-0.5">
      Total Interaction:
      <span class="tw-font-normal">
        {{ search_person_form.length + vehicle_form.length }}</span
      >
    </v-list-item-title>
    <v-list-item-title class="tw-py-0.5">
      Total Stop and Search Person:
      <span class="tw-font-normal"> {{ search_person_form.length }}</span>
    </v-list-item-title>
    <v-list-item-title class="tw-py-0.5">
      Total Stop and Search Vehicle:
      <span class="tw-font-normal"> {{ vehicle_form.length }}</span>
    </v-list-item-title>

    <div v-if="search_person_form.length > 0">
      <div class="tw-py-0.5 tw-my-2 tw-bg-gray-200">
        <h4 class="tw-text-xs tw-font-semibold tw-px-2 tw-uppercase">
          Last Stop and Search Person
        </h4>
      </div>

      <v-list-item-title class="tw-py-0.5"
        >Date:
        <span class="tw-font-normal">
          {{ formateDate(search_person_form[0].createdAt) }}
        </span>
        <span class="tw-font-bold"
          >&nbsp;&nbsp;({{ Ago(search_person_form[0].createdAt) }})</span
        >
      </v-list-item-title>

      <v-list-item-title class="tw-py-0.5"
        >Action Taken:
        <span class="tw-font-normal tw-overflow-auto tw-whitespace-pre-line">
          {{ search_person_form[0].action_taken }}
        </span>
      </v-list-item-title>
      <v-list-item-title class="tw-py-0.5" v-if="search_person_form[0].fine"
        >Fine:
        <span class="tw-font-normal"> {{ search_person_form[0].fine }}</span>
      </v-list-item-title>
      <v-list-item-title class="tw-py-0.5">
        <v-list-item-title class="tw-py-0.5"
          >Reason to Stop:
          <span
            class="tw-font-normal dhivehi tw-whitespace-pre-line tw-flex tw-justify-end"
          >
            {{ search_person_form[0].reason_to_stop }}</span
          >
        </v-list-item-title>
        Interaction:
        <span
          class="tw-font-normal tw-text-xs tw-capitalize normal tw-overflow-auto tw-whitespace-pre-line"
        >
          Location: Sh.Milandhoo /
          {{ search_person_form[0].location }}, Details:
          {{ search_person_form[0].notes }}
        </span>
      </v-list-item-title>

      <ViewAllPersonInteraction
        :records="personRecords.slice(1)"
        v-if="search_person_form.length > 1"
      />
    </div>

    <div v-if="vehicle_form.length > 0">
      <div class="tw-py-0.5 tw-my-2 tw-bg-gray-200">
        <h4 class="tw-text-xs tw-font-semibold tw-px-2 tw-uppercase">
          Last Stop and Search Vehicle
        </h4>
      </div>

      <v-list-item-title class="tw-py-0.5"
        >Date:
        <span class="tw-font-normal">
          {{ formateDate(vehicle_form[0].createdAt) }}
        </span>
        <span class="tw-font-bold"
          >&nbsp;&nbsp;({{ Ago(vehicle_form[0].createdAt) }})</span
        >
      </v-list-item-title>

      <v-list-item-title class="tw-py-0.5"
        >Vehicle No:
        <span class="tw-font-normal tw-overflow-auto tw-whitespace-pre-line">
          {{ vehicle_form[0].vehicle_no }}
        </span>
      </v-list-item-title>
      <v-list-item-title class="tw-py-0.5"
        >Reason to Stop:
        <span class="tw-font-normal dhivehi tw-flex tw-justify-end">
          {{ vehicle_form[0].reason_to_stop }}</span
        >
      </v-list-item-title>
      <v-list-item-title class="tw-py-0.5"
        >Action Taken:
        <span class="tw-font-normal"> {{ vehicle_form[0].action_taken }}</span>
      </v-list-item-title>
      <v-list-item-title class="tw-py-0.5">
        Interaction:
        <span
          class="tw-font-normal tw-text-xs tw-capitalize normal tw-overflow-auto tw-whitespace-pre-line"
        >
          Location: Sh.Milandhoo /
          {{ vehicle_form[0].location }}, Details:
          {{ vehicle_form[0].notes }}
        </span>
      </v-list-item-title>

      <ViewAllVehicleInteraction
        :records="vehicleRecords.slice(1)"
        v-if="vehicle_form.length > 1"
      />
    </div>
  </div>
</template>

<script>
import ViewAllPersonInteraction from "./ViewAllPersonInteraction.vue";
import ViewAllVehicleInteraction from "./ViewAllVehicleInteraction.vue";
import moment from "moment";

export default {
  props: ["search_person_form", "vehicle_form"],

  components: { ViewAllPersonInteraction, ViewAllVehicleInteraction },

  data() {
    return {
      showAllPersonInteraction: false,
      personRecords: this.search_person_form,
      vehicleRecords: this.vehicle_form,
    };
  },

  methods: {
    formateDate(value) {
      const date = moment(value).format("DD/MM/YYYY HH:mm:ss");
      return date;
    },

    Ago: function (date) {
      return moment(date).fromNow();
    },
  },
};
</script>
