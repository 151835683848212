import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";

import Main from "../views/Main.vue";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import Interaction from "../views/Interaction.vue";
import NotFound from "../views/NotFound.vue";
import AddPerson from "../views/locals/AddPerson.vue";
import EditPerson from "../views/locals/EditPerson.vue";
import FilterRecords from "../views/FilterRecords.vue";
import EditRecord from "../views/EditRecord.vue";

import AdminLogin from "../views/admin/AdminLogin.vue";
import AdminDashboard from "../views/admin/AdminDashboard.vue";
import ManageOfficers from "../views/admin/ManageOfficers.vue";
import EditOfficer from "../components/admin/EditOfficer.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    component: Main,
    redirect: "/dashboard",
    beforeEnter: guradUser,
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        beforeEnter: guradUser,
      },
      {
        path: "/interaction",
        name: "Interaction",
        component: Interaction,
        beforeEnter: guradUser,
      },
      {
        path: "/filter-records",
        name: "FilterRecords",
        component: FilterRecords,
        props: true,
        beforeEnter: guradUser,
      },
      {
        path: "/add-person",
        name: "AddPerson",
        component: AddPerson,
        beforeEnter: guradUser,
      },
      {
        path: "/edit/person",
        name: "EditPerson",
        component: EditPerson,
        beforeEnter: guradUser,
      },
      {
        path: "/edit/record/:id",
        name: "EditRecord",
        component: EditRecord,
        beforeEnter: guradUser,
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/logout",
    beforeEnter: logout,
  },
  {
    path: "/admin",
    name: "AdminLogin",
    component: AdminLogin,
  },
  {
    path: "/admin/dashboard",
    name: "AdminDashboard",
    component: AdminDashboard,
    beforeEnter: guradAdmin,
  },
  {
    path: "/admin/officers",
    name: "ManageOfficers",
    component: ManageOfficers,
    beforeEnter: guradAdmin,
  },
  {
    path: "/admin/officers/edit",
    name: "EditOfficer",
    component: EditOfficer,
    beforeEnter: guradAdmin,
    props: true,
  },
  {
    path: "/admin/logout",
    beforeEnter: adminLogout,
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

async function logout(to, from, next) {
  sessionStorage.removeItem("UserAccessToken");
  await axios.post("/api/v1/auth?task=logout");
  next("/login");
}

function adminLogout(to, from, next) {
  localStorage.removeItem("admin_login");
  next("/admin");
}

async function guradAdmin(to, from, next) {
  var isAuthenticated = false;
  var token = localStorage.getItem("admin_login");
  if (token) {
    await axios
      .get("/api/v1/admin/auth", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.status != 200) {
          isAuthenticated = false;
        } else {
          isAuthenticated = true;
        }
      })
      .catch(() => {
        isAuthenticated = false;
      });
  } else {
    isAuthenticated = false;
  }
  if (isAuthenticated) {
    next();
  } else {
    localStorage.clear();
    next("/admin");
  }
}

async function guradUser(to, from, next) {
  let isAuthenticated = false;
  const token = sessionStorage.getItem("UserAccessToken");
  if (token) {
    await axios
      .get("/api/v1/auth", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.status != 200) {
          isAuthenticated = false;
        } else {
          isAuthenticated = true;
        }
      })
      .catch(() => {
        isAuthenticated = false;
      });
  } else {
    isAuthenticated = false;
  }
  if (isAuthenticated) {
    next();
  } else {
    localStorage.clear();
    next("/login");
  }
}

export default router;
