<template>
  <div class="tw-m-10">
    <h1 class="tw-text-2xl tw-text-appColor tw-font-semibold">Add Officer</h1>
    <v-divider></v-divider>
    <v-form v-model="valid" ref="form">
      <div class="tw-grid sm:tw-grid-cols-2 tw-grid-rows-1 tw-gap-4 tw-my-6">
        <v-text-field
          label="Officer name"
          v-model="officerName"
          :rules="rules.name"
          required
        ></v-text-field>
        <v-text-field
          label="Service number"
          v-model="service"
          :rules="rules.service"
          required
        ></v-text-field>
        <v-text-field
          label="Username"
          v-model="username"
          :rules="rules.username"
          autocomplete="username"
          required
        ></v-text-field>
        <v-text-field
          label="Password"
          v-model="password"
          type="password"
          :rules="[rules.required, rules.min]"
          autocomplete="new-password"
          required
        ></v-text-field>
        <v-btn elevation="2" color="primary" width="100%" @click="validate()"
          ><span class="tw-text-white">ADD</span></v-btn
        >
      </div>
    </v-form>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="40"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar">
      {{ snackbarMsg }}
    </v-snackbar>
  </div>
</template>

<script>
import api from "../../plugins/request";
export default {
  data() {
    return {
      loading: false,
      valid: false,
      officerName: "",
      service: "",
      username: "",
      password: "",
      snackbar: false,
      snackbarMsg: "",
      rules: {
        name: [
          (v) => !!v || "Name is required",
          (v) => v.length >= 6 || "Min 6 characters",
        ],
        required: (value) => !!value || "Password is required",
        min: (v) => v.length >= 8 || "Min 8 characters",
        username: [
          (v) => !!v || "Username is required",
          (v) => v.length >= 4 || "Min 4 characters",
        ],
        service: [
          (v) => !!v || "Service number is required",
          (v) => v.length >= 4 || "Min 4 characters",
        ],
      },
    };
  },

  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.valid) {
        this.add();
      }
    },

    async add() {
      this.loading = true;
      const res = await api.post("/api/v1/admin?task=add_officer", {
        name: this.officerName,
        serviceNumber: this.service,
        username: this.username,
        password: this.password,
      });
      const response = res.data;
      console.log(response);
      if (response.status == false) {
        if (response.message == "username must be unique") {
          this.snackbarMsg = "Username already exist.";
          this.snackbar = true;
        } else if (response.message == "service_number must be unique") {
          this.snackbarMsg = "Service number already exist.";
          this.snackbar = true;
        } else {
          this.snackbarMsg = "Something went wrong. Please try again.";
          this.snackbar = true;
        }
      }
      if (response.status == true) {
        location.reload();
      }
      this.loading = false;
    },
  },
};
</script>
