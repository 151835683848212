<template>
  <div class="tw-w-full">
    <div class="tw-max-w-screen-2xl sm:tw-p-10 tw-p-4 tw-mx-auto">
      <div class="tw-max-w-screen-md tw-mx-auto" v-if="this.isNoRecord == true">
        <div class="center">
          <img src="@/assets/no-result.svg" />
          <h1 class="tw-font-bold tw-text-lg sm:tw-text-2xl tw-py-2">
            No result found
          </h1>
        </div>
      </div>
      <div v-else class="tw-max-w-screen-md tw-mx-auto">
        <search-person
          :personData="personInfo"
          v-on:personInfo="getPerson"
        ></search-person>

        <v-card class="tw-my-4 tw-p-6">
          <FormPerson
            :personData="record"
            :isEditAble="true"
            v-on:form="edit"
            v-if="record.form_type == 'search_person_forms'"
          ></FormPerson>
        </v-card>
      </div>
    </div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>

    <v-snackbar v-model="snackbar.visible" top :color="snackbar.color">
      {{ snackbar.message
      }}<template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.visible = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import SearchPerson from "../components/interaction/SearchPerson.vue";
import api from "../plugins/request";
import FormPerson from "../components/interaction/FormPerson.vue";

export default {
  components: { SearchPerson, FormPerson },
  data() {
    return {
      isNoRecord: false,
      record_id: null,
      record: {},
      personInfo: null,
      form: null,
      snackbar: {
        visible: false,
        message: "",
        color: "primary",
      },
      overlay: false,
    };
  },
  methods: {
    async edit(value) {
      this.form = value;
      if (!this.personInfo) {
        this.snackbar.color = "red accent-2";
        this.snackbar.message = "Select a person";
        this.snackbar.visible = true;
      } else {
        this.overlay = true;
        if (this.$route.query.type == "search-person") {
          const res = await api.post(
            "/api/v1/client/form?task=edit-search-person",
            {
              record_id: this.record_id,
              person: this.personInfo,
              form: this.form,
            },
            false
          );

          if (res.data.status) {
            this.$router.push({
              name: "FilterRecords",
              params: { message: res.data.message },
            });
          } else {
            this.overlay = false;
            this.snackbar.color = "warning";
            this.snackbar.message = "Somethin went wrong. Please try again.";
            this.snackbar.visible = true;
          }
        }
      }
    },

    async fetchrecord(id) {
      const res = await api.post(
        "/api/v1/client/records?task=get-search-person-by-id",
        { id },
        false
      );

      if (res.status == 200) {
        if (res.data.status == true) {
          this.record = res.data.data;
          this.personInfo = res.data.data.person;
          this.record_id = res.data.data.id;
        } else {
          this.isNoRecord = true;
        }
      }
    },

    async validatePath() {
      const path = this.$route;
      if (
        !path.params.id ||
        path.query.length == 0 ||
        !path.query.type ||
        path.query.type !== "search-person"
      ) {
        return this.$router.push("/filter-records");
      }
      return await this.fetchrecord(path.params.id);
    },

    getPerson(value) {
      this.personInfo = value;
    },
  },

  async created() {
    await this.validatePath();
  },
};
</script>

<style scoped>
.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
