<template>
  <div class="tw-grid tw-place-items-center tw-h-screen">
    <div
      class="tw-w-72 tw-m-4 tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-6"
    >
      <h1
        class="tw-space-y-1.5 tw-text-appColor tw-py-2 tw-text-2xl tw-font-bold tw-uppercase"
      >
        Milpo
      </h1>
      <div class="tw-w-full tw-bg-gray-100" style="height: 2px"></div>

      <div
        class="tw-w-full tw-h-9 tw-rounded-lg border-outline elevation-4 tw-my-2 tw-mt-4 tw-flex"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="tw-h-5 tw-w-5 tw-mt-2 tw-mx-2"
          viewBox="0 0 20 20"
          fill="#023e8a"
        >
          <path
            fill-rule="evenodd"
            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
            clip-rule="evenodd"
          />
        </svg>

        <input
          v-model="username"
          type="text"
          class="focus:tw-outline-none tw-font-normal tw-text-sm"
          placeholder="Username"
        />
      </div>
      <div
        class="tw-w-full tw-h-9 tw-rounded-lg border-outline elevation-4 tw-my-2 tw-mt-4 tw-flex"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="tw-h-5 tw-w-5 tw-mt-2 tw-mx-2"
          viewBox="0 0 20 20"
          fill="#023e8a"
        >
          <path
            fill-rule="evenodd"
            d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z"
            clip-rule="evenodd"
          />
        </svg>
        <input
          v-model="password"
          type="password"
          class="focus:tw-outline-none tw-font-normal tw-text-sm"
          placeholder="Password"
        />
      </div>
      <v-btn
        elevation="2"
        color="#023e8a"
        class="tw-my-2"
        width="100%"
        @click="signIn"
        ><span class="tw-text-white">Login</span></v-btn
      >
    </div>
    <v-snackbar v-model="snackbar">
      Incorrect username or password
      <template>
        <v-btn color="#023e8a" text @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      snackbar: false,
      username: "",
      password: "",
      message: "",
    };
  },

  methods: {
    async signIn() {
      const username = this.username;
      const password = this.password;
      const res = await axios.post("/api/v1/auth?task=login", {
        username,
        password,
      });
      if (res.data.status) {
        this.$store.commit("setUserAccessToken", res.data.data);
        this.$router.push("/");
      } else {
        this.snackbar = true;
        this.message = res.data.message;
      }
    },

    async getToken() {
      const res = await axios.post(
        "/api/v1/auth?task=refresh-token",
        {},
        { withCredentials: true }
      );
      if (res.data.status) {
        this.$store.commit("setUserAccessToken", res.data.data);
        this.$router.push("/");
      }
    },
  },

  created() {
    this.getToken();
  },
};
</script>

<style scoped>
.border-outline {
  border: solid #023e8a;
  border-width: 1px 1px;
}
</style>
