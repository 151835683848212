<template>
  <div>
    <admin-navbar :activeBar="1"></admin-navbar>
    <div class="tw-w-full">
      <div class="tw-max-w-screen-2xl tw-mx-auto">
        <!-- Add officers -->
        <add-officer></add-officer>
        <!-- Manage officers -->
        <officer></officer>
      </div>
    </div>
  </div>
</template>

<script>
import AdminNavbar from "../../components/admin/AdminNavbar.vue";
import AddOfficer from "../../components/admin/AddOfficer.vue";
import Officer from "../../components/admin/Officers.vue";
export default {
  components: { AdminNavbar, AddOfficer, Officer },
  data() {
    return {};
  },
};
</script>
