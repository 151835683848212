<template>
  <nav>
    <v-app-bar color="primary" fixed elevation="4" dark app>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title
        class="text-uppercase tw-text-white tw-text-lg tw-font-bold"
      >
        MILANDHOO POLICE ADMIN
      </v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" temporary app>
      <v-list nav dense>
        <v-list-item-group v-model="selectActive" color="primary">
          <router-link v-for="(item, i) in items" :key="i" :to="item.path">
            <v-list-item>
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
export default {
  props: {
    activeBar: Number,
  },
  data: () => ({
    drawer: false,
    selectActive: 0,
    items: [
      { text: "Dashboard", icon: "mdi-dots-grid", path: "/admin/dashboard" },
      {
        text: "Manage Officers",
        icon: "mdi-account-multiple",
        path: "/admin/officers",
      },
      { text: "Logout", icon: "mdi-exit-to-app", path: "logout" },
    ],
  }),
  methods: {
    currentPath(path) {
      if (this.$route.path == path) {
        console.log(true);
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.selectActive = this.activeBar;
  },
};
</script>

<style></style>
