<template>
  <div>
    <v-card v-for="record in records" :key="record.id" class="tw-my-4">
      <v-list dense v-if="record.person">
        <v-list-item>
          <v-list-item-content>
            <div
              class="tw-py-0.5 tw-my-2 tw-bg-gray-200 tw-w-full tw-flex tw-justify-between"
            >
              <h4 class="tw-text-xs tw-font-semibold tw-px-2 tw-uppercase">
                Person Details
              </h4>
              <router-link
                :to="`/edit/record/${record.id}?type=search-person`"
                class="tw-text-xs tw-font-semibold tw-px-2 tw-uppercase tw-underline tw-text-appColor"
              >
                Edit
              </router-link>
            </div>
            <v-list-item-title class="tw-py-0.5">
              NAME:
              <span class="tw-font-normal"> {{ record.person.name }}</span>
            </v-list-item-title>
            <v-list-item-title class="tw-py-0.5"
              >ID NUMBER:
              <span class="tw-font-normal"> {{ record.person.id_no }}</span>
            </v-list-item-title>
            <v-list-item-title class="tw-py-0.5 tw-uppercase"
              >licence No:
              <span class="tw-font-normal">
                {{ record.person.licence_no }}</span
              >
            </v-list-item-title>
            <v-list-item-title class="tw-py-0.5">
              DOB:
              <span class="tw-font-normal">{{
                getAge(record.person.dob)
              }}</span>
            </v-list-item-title>
            <v-list-item-title class="tw-py-0.5">
              MOBILE:
              <span class="tw-font-normal">{{ record.person.mobile }}</span>
            </v-list-item-title>
            <v-list-item-title class="tw-py-0.5">
              ADDRESS:
              <span class="tw-font-normal">
                {{ record.person.permanent_address }}
              </span>
            </v-list-item-title>
            <v-list-item-title class="tw-py-0.5">
              CURRENT ADDRESS:
              <span class="tw-font-normal">
                {{ record.person.current_address }}
              </span>
            </v-list-item-title>
            <div class="tw-py-1 tw-my-2 tw-bg-gray-200">
              <h4 class="tw-text-xs tw-font-semibold tw-px-2 tw-uppercase">
                Interaction Details
              </h4>
            </div>
            <v-list-item-title class="tw-py-0.5"
              >Date:
              <span class="tw-font-normal">
                {{ formateDate(record.createdAt) }}
              </span>
              <span class="tw-font-bold"
                >&nbsp;&nbsp;({{ Ago(record.createdAt) }})</span
              >
            </v-list-item-title>

            <v-list-item-title class="tw-py-0.5"
              >Reason to Stop:
              <span
                class="tw-font-normal tw-w-full dhivehi tw-text-right tw-whitespace-pre-line"
              >
                <ol class="tw-list-decimal" style="direction: RTL">
                  <li
                    v-for="(reason, i) in getRecord(record.reason_to_stop)"
                    :key="reason"
                  >
                    {{ i + 1 }} - {{ reason }}
                  </li>
                </ol>
              </span>
            </v-list-item-title>

            <v-list-item-title class="tw-py-0.5"
              >Action Taken:
              <span
                class="tw-font-normal tw-overflow-auto tw-whitespace-pre-line"
              >
                {{ record.action_taken }}</span
              ></v-list-item-title
            >
            <v-list-item-title class="tw-py-0.5" v-if="record.fine"
              >Fine:
              <span class="tw-font-normal"> {{ record.fine }}</span>
            </v-list-item-title>

            <v-list-item-title class="tw-py-0.5" v-if="record.notes">
              Details:
              <span
                class="tw-font-normal tw-text-xs tw-capitalize normal tw-overflow-auto tw-whitespace-pre-line"
              >
                {{ record.notes }}
              </span>
            </v-list-item-title>

            <v-list-item-title class="tw-py-0.5">
              Location: Sh.Milandhoo /
              <span
                class="tw-font-normal tw-text-xs tw-capitalize normal tw-overflow-auto tw-whitespace-pre-line"
              >
                {{ record.location }}
              </span>
            </v-list-item-title>

            <div class="tw-py-0.5 tw-my-2 tw-bg-gray-200">
              <h4 class="tw-text-xs tw-font-semibold tw-px-2 tw-uppercase">
                Officers Details
              </h4>
            </div>
            <v-list-item-title class="tw-py-0.5">
              <div
                v-for="officer in record.officers"
                :key="officer.service_number"
              >
                <span class="tw-text-xs tw-my-2">
                  {{ officer.officer_name }}
                </span>
                <span class="tw-text-xs tw-font-bold">
                  ({{ officer.service_number }})
                </span>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import age from "s-age";

export default {
  props: ["records"],
  data() {
    return {};
  },

  methods: {
    getAge(value) {
      const dob = moment(value).format("DD/MM/YYYY");
      const yearsOld = age(value);
      const outputDate = `${dob}, (${yearsOld} yrs)`;
      return outputDate;
    },

    formateDate(value) {
      const date = moment(value).format("DD/MM/YYYY HH:mm:ss");
      return date;
    },

    Ago: function (date) {
      return moment(date).fromNow();
    },

    getRecord: function (value) {
      let points = "";
      if (!value) return points;
      points = value.split(",");
      return points;
    },
  },
};
</script>
