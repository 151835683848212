<template>
  <div class="tw-w-full tw-max-w-screen-lg">
    <div
      class="tw-grid sm:tw-grid-cols-3 tw-grid-rows-3 tw-mx-5 tw-gap-4 tw-py-5"
    >
      <v-btn
        @click="bottomNavbar('/interaction?type=person')"
        class="tw-w-full btn-ssp tw-flex tw-justify-between"
        height="100px"
        color="primary"
      >
        Stop and Search Person
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="tw-h-8 tw-w-8"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
          />
        </svg>
      </v-btn>
      <v-btn
        @click="bottomNavbar('/interaction?type=vehicle')"
        class="tw-w-full btn-ssv tw-flex tw-flex-wrap tw-justify-between"
        height="100px"
        color="primary"
        >Stop and Search Vehicle
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="tw-h-8 tw-w-8"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
          />
        </svg>
      </v-btn>
      <v-btn
        @click="bottomNavbar('/filter-records')"
        class="tw-w-full btn-fr tw-flex tw-justify-between"
        height="100px"
        color="primary"
        >Filter Records
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="tw-h-8 tw-w-8"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
          />
        </svg>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    bottomNavbar(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>
.btn-ssp {
  background: rgb(78, 140, 253);
  background: linear-gradient(
    -90deg,
    rgba(78, 140, 253, 1) 1%,
    rgba(34, 86, 151, 0.9416360294117647) 100%
  );
}

.btn-ssv {
  background: rgb(58, 143, 170);
  background: linear-gradient(
    90deg,
    rgba(58, 143, 170, 1) 1%,
    rgba(45, 201, 208, 0.9416360294117647) 100%
  );
}

.btn-fr {
  background: rgb(153, 61, 250);
  background: linear-gradient(
    90deg,
    rgba(153, 61, 250, 1) 0%,
    rgba(186, 73, 255, 0.8856136204481793) 100%
  );
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
