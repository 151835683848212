import axios from "axios";
import store from "../store";

const api = {
  post: async function (url, body, admin = true) {
    let token = admin
      ? localStorage.getItem("admin_login")
      : store.getters.getUserAccessToken;

    const res = await axios.post(url, body, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return res;
  },
};

export default api;
