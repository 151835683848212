<template>
  <div class="tw-w-full">
    <div class="tw-max-w-screen-2xl sm:tw-p-10 tw-p-4 tw-mx-auto">
      <div class="tw-max-w-screen-md tw-mx-auto">
        <div>
          <v-card>
            <div
              class="tw-grid tw-gap-4 sm:tw-grid-cols-2 tw-grid-rows-1 tw-justify-center tw-content-center tw-p-4"
            >
              <v-select
                class=""
                dense
                outlined
                hide-details
                v-model="selectedRecordType"
                :items="typeOfRecords"
                item-text="name"
                item-value="id"
                label="Form"
              ></v-select>
              <div class="tw-flex">
                <find-person v-on:personInfo="personInfo"></find-person>
                <v-btn
                  @click="downloadFile"
                  class="tw-mx-2 tw-py-6"
                  height="40"
                  outlined
                  color="#023e8a"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </div>
            </div>

            <v-expand-transition>
              <v-list v-if="person" dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="tw-py-0.5">
                      NAME:
                      <span class="tw-font-normal"> {{ person.name }}</span>
                    </v-list-item-title>
                    <v-list-item-title class="tw-py-0.5"
                      >ID NUMBER:
                      <span class="tw-font-normal"> {{ person.id_no }}</span>
                    </v-list-item-title>
                    <v-list-item-title class="tw-py-0.5 tw-uppercase"
                      >licence No:
                      <span class="tw-font-normal">
                        {{ person.licence_no }}</span
                      >
                    </v-list-item-title>
                    <v-list-item-title class="tw-py-0.5"
                      >DOB:
                      <span class="tw-font-normal">{{
                        getAge(person.dob)
                      }}</span>
                    </v-list-item-title>
                    <v-list-item-title class="tw-py-0.5">
                      MOBILE:
                      <span class="tw-font-normal">{{ person.mobile }}</span>
                    </v-list-item-title>
                    <v-list-item-title class="tw-py-0.5">
                      ADDRESS:
                      <span class="tw-font-normal">
                        {{ person.permanent_address }}
                      </span>
                    </v-list-item-title>
                    <v-list-item-title class="tw-py-0.5">
                      CURRENT ADDRESS:
                      <span class="tw-font-normal">
                        {{ person.current_address }}
                      </span>
                    </v-list-item-title>
                    <div class="tw-py-1 tw-my-2 tw-bg-gray-200">
                      <h4
                        class="tw-text-sm tw-font-semibold tw-px-2 tw-uppercase"
                      >
                        Interaction
                      </h4>
                    </div>
                    <v-list-item-title class="tw-py-0.5">
                      Total Interaction:
                      <span class="tw-font-normal">
                        {{
                          search_person_form.length + vehicle_form.length
                        }}</span
                      >
                    </v-list-item-title>
                    <v-list-item-title class="tw-py-0.5">
                      Total Stop and Search Person:
                      <span class="tw-font-normal">
                        {{ search_person_form.length }}</span
                      >
                    </v-list-item-title>
                    <v-list-item-title class="tw-py-0.5">
                      Total Stop and Search Vehicle:
                      <span class="tw-font-normal">
                        {{ vehicle_form.length }}</span
                      >
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expand-transition>
          </v-card>
        </div>

        <div v-if="person">
          <person-search
            :records="search_person_form"
            v-if="selectedRecordType == 1"
          ></person-search>

          <person-vehicle v-else :records="vehicle_form"></person-vehicle>
        </div>

        <div v-else class="tw-my-4">
          <search-person-card
            v-if="selectedRecordType == 1"
            :records="records"
          ></search-person-card>
          <vehicle-card
            v-else-if="selectedRecordType == 2"
            :records="records"
          ></vehicle-card>
        </div>
      </div>
    </div>

    <v-snackbar v-model="snackbar.visible" top :color="snackbar.color">
      {{ snackbar.message
      }}<template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.visible = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import FindPerson from "../components/filter-records/FindPerson.vue";
import SearchPersonCard from "../components/filter-records/SearchPersonCard.vue";
import VehicleCard from "../components/filter-records/VehicleCard.vue";

import api from "../plugins/request";
import xlsx from "json-as-xlsx";
import age from "s-age";
import moment from "moment";
import PersonSearch from "../components/filter-records/PersonSearch.vue";
import PersonVehicle from "../components/filter-records/PersonVehicle.vue";

export default {
  components: {
    VehicleCard,
    SearchPersonCard,
    FindPerson,
    PersonSearch,
    PersonVehicle,
  },

  data() {
    return {
      selectedRecordType: 1,
      typeOfRecords: [
        { id: 1, name: "STOP AND SEARCH PERSON" },
        { id: 2, name: "STOP AND SEARCH VEHICLE" },
      ],
      records: [],
      person: "",
      search_person_form: [],
      vehicle_form: [],
      snackbar: {
        visible: false,
        message: "",
        color: "success",
      },
    };
  },

  methods: {
    async fetchVehicle() {
      const res = await api.post(
        "/api/v1/client/records?task=get-all-vehicle-forms",
        "",
        false
      );

      if (res.data.status) {
        this.records = res.data.data;
      }
    },

    async fetchSearchPerson() {
      const res = await api.post(
        "/api/v1/client/records?task=get-all-search-person-forms",
        "",
        false
      );
      if (res.data.status) {
        this.records = res.data.data;
      }
    },

    async getPersonRecords(person_id) {
      const res = await api.post(
        "/api/v1/client/records?task=get-records-by-id",
        {
          person_id,
        },
        false
      );
      if (res.data.status) {
        this.records = res.data.data[0];
        if (this.records.search_person_forms.length > 0) {
          this.search_person_form = this.records.search_person_forms;
        } else {
          this.search_person_form = [];
        }

        if (this.records.vehicle_forms.length > 0) {
          this.vehicle_form = this.records.vehicle_forms;
        } else {
          this.vehicle_form = [];
        }
      }
    },

    getAge(value) {
      const dob = moment(value).format("DD/MM/YYYY");
      const yearsOld = age(value);
      const outputDate = `${dob}, (${yearsOld} yrs)`;
      return outputDate;
    },

    personInfo(value) {
      this.person = value;
      if (value) {
        this.getPersonRecords(value.id);
      } else {
        if (this.selectedRecordType == 1) {
          this.fetchSearchPerson();
        } else {
          this.fetchVehicle();
        }
      }
    },

    getOfficers(value) {
      const data = [];
      value.forEach((val) => {
        data.push(val.service_number);
      });
      return data.toString();
    },

    downloadSsvForm() {
      let content = [];
      let officers;
      this.records.forEach((rec) => {
        officers = this.getOfficers(rec.officers);
        content.push({
          name: rec.person.name,
          id_no: rec.person.id_no,
          licence_no: rec.person.licence_no,
          current_address: rec.person.current_address,
          vehicle_no: rec.vehicle_no,
          reason_to_stop: rec.reason_to_stop,
          action_taken: rec.action_taken,
          annual_sticker: rec.annual_sticker,
          insurance_sticker: rec.insurance_sticker,
          roadworthiness: rec.roadworthiness,
          notes: rec.notes,
          location: rec.location,
          officers: officers,
          date: moment(rec.createdAt).format("DD/MM/YYYY, HH:mm"),
        });
      });

      let data = [
        {
          sheet: "STOP AND SEARCH VEHICLE",
          columns: [
            { label: "NAME", value: "name" },
            { label: "ID NUMBER", value: "id_no" },
            { label: "LICENCE NO", value: "licence_no" },
            { label: "CURRENT ADDRESS", value: "current_address" },
            { label: "VEHICLE NO", value: "vehicle_no" },
            { label: "REASON TO STOP", value: "reason_to_stop" },
            { label: "ACTION TAKEN", value: "action_taken" },
            { label: "ANNUAL STICKER", value: "annual_sticker" },
            { label: "INSURANCE STICKER", value: "insurance_sticker" },
            { label: "ROADWORTHINESS", value: "roadworthiness" },
            { label: "LOCATION", value: "location" },
            { label: "NOTES", value: "notes" },
            { label: "OFFICERS", value: "officers" },
            { label: "DATE", value: "date" },
          ],
          content,
        },
      ];
      let settings = {
        fileName: "MySpreadsheet",
        extraLength: 3,
      };
      xlsx(data, settings);
    },

    downloadSspForm() {
      let content = [];
      let officers;
      this.records.forEach((rec) => {
        officers = this.getOfficers(rec.officers);
        content.push({
          name: rec.person.name,
          id_no: rec.person.id_no,
          licence_no: rec.person.licence_no,
          current_address: rec.person.current_address,
          action_taken: rec.action_taken,
          fine: rec.fine,
          notes: rec.notes,
          location: rec.location,
          officers: officers,
          date: moment(rec.createdAt).format("DD/MM/YYYY, HH:mm"),
        });
      });

      let data = [
        {
          sheet: "STOP AND SEARCH PERSON",
          columns: [
            { label: "NAME", value: "name" },
            { label: "ID NUMBER", value: "id_no" },
            { label: "LICENCE NO", value: "licence_no" },
            { label: "CURRENT ADDRESS", value: "current_address" },
            { label: "ACTION TAKEN", value: "action_taken" },
            { label: "FINE", value: "fine" },
            { label: "LOCATION", value: "location" },
            { label: "NOTES", value: "notes" },
            { label: "OFFICERS", value: "officers" },
            { label: "DATE", value: "date" },
          ],
          content,
        },
      ];
      let settings = {
        fileName: "MySpreadsheet",
        extraLength: 3,
      };
      xlsx(data, settings);
    },

    downloadFile() {
      if (this.selectedRecordType == 1 && this.person == "") {
        this.downloadSspForm();
      } else if (this.selectedRecordType == 2 && this.person == "") {
        this.downloadSsvForm();
      }
    },

    getSnackBarMessage() {
      const info = this.$route.params;
      if (info.message) {
        this.snackbar.message = info.message;
        this.snackbar.visible = true;
      }
    },
  },

  watch: {
    selectedRecordType(value) {
      if (value == 1) {
        this.fetchSearchPerson();
      } else {
        this.fetchVehicle();
      }
    },
  },

  created() {
    this.getSnackBarMessage();
    this.fetchSearchPerson();
  },
};
</script>
