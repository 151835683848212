<template>
  <div class="tw-m-10">
    <h1 class="tw-text-2xl tw-text-appColor tw-font-semibold">Officers</h1>
    <v-divider></v-divider>
    <v-simple-table class="tw-my-6">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Officer Name</th>
            <th class="text-left">Service Number</th>
            <th class="text-left">Ban</th>
            <th class="text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in officers" :key="item.id">
            <td>{{ item.officer_name }}</td>
            <td>{{ item.service_number }}</td>
            <td>
              <v-icon
                @click="ban(item.id, item.is_user_ban)"
                v-text="
                  item.is_user_ban == false
                    ? 'mdi-account'
                    : 'mdi-account-cancel'
                "
                :color="item.is_user_ban == false ? 'green' : 'red'"
                class="tw-mx-2"
              ></v-icon>
            </td>
            <td>
              <v-icon v-text="'mdi-chart-bar'" color="primary"></v-icon>
              <router-link
                :to="{
                  name: 'EditOfficer',
                  params: {
                    id: item.id,
                    name: item.officer_name,
                    service: item.service_number,
                    username: item.username,
                    ban: item.is_user_ban,
                  },
                }"
              >
                <v-icon
                  v-text="'mdi-application-edit'"
                  color="green"
                  class="tw-mx-2"
                ></v-icon>
              </router-link>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import api from "../../plugins/request";
export default {
  data() {
    return {
      officers: [],
    };
  },
  methods: {
    async fetchOfficers() {
      const res = await api.post("/api/v1/admin?task=officer_info");
      if (res.status == 200) {
        this.officers = res.data.data;
      }
    },

    async ban(id, ban) {
      await api.post("/api/v1/admin?task=officer_ban", {
        id,
        ban: !ban,
      });

      this.fetchOfficers();
    },
  },

  created() {
    this.fetchOfficers();
  },
};
</script>
