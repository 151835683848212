<template>
  <div>
    <v-card v-for="record in records" :key="record.id" class="tw-my-4">
      <v-list dense>
        <v-list-item>
          <v-list-item-content>
            <div class="tw-py-0.5 tw-my-2 tw-bg-gray-200">
              <h4 class="tw-text-xs tw-font-semibold tw-px-2 tw-uppercase">
                Interaction Details
              </h4>
            </div>
            <v-list-item-title class="tw-py-0.5"
              >Vehicle No:
              <span
                class="tw-font-normal tw-overflow-auto tw-whitespace-pre-line"
              >
                {{ record.vehicle_no }}</span
              ></v-list-item-title
            >
            <v-list-item-title class="tw-py-0.5"
              >Reason to Stop:
              <span class="tw-font-normal dhivehi">
                {{ record.reason_to_stop }}</span
              >
            </v-list-item-title>
            <v-list-item-title class="tw-py-0.5"
              >Action Taken:
              <span class="tw-font-normal"> {{ record.action_taken }}</span>
            </v-list-item-title>
            <v-list-item-title class="tw-py-0.5">
              Interaction:
              <span
                class="tw-font-normal tw-text-xs tw-capitalize normal tw-overflow-auto tw-whitespace-pre-line"
              >
                {{ Ago(record.createdAt) }}, Location: Sh.Milandhoo /
                {{ record.location }}, Details:
                {{ record.notes }}
              </span>
            </v-list-item-title>
            <div class="tw-py-0.5 tw-my-2 tw-bg-gray-200">
              <h4 class="tw-text-xs tw-font-semibold tw-px-2 tw-uppercase">
                Officers Details
              </h4>
            </div>
            <v-list-item-title class="tw-py-0.5">
              <div
                v-for="officer in record.officers"
                :key="officer.service_number"
              >
                <span class="tw-text-xs tw-my-2">
                  {{ officer.officer_name }}
                </span>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import age from "s-age";

export default {
  props: ["records"],
  data() {
    return {};
  },

  methods: {
    getAge(value) {
      const dob = moment(value).format("DD/MM/YYYY");
      const yearsOld = age(value);
      const outputDate = `${dob}, (${yearsOld} yrs)`;
      return outputDate;
    },

    Ago: function (date) {
      return moment(date).fromNow();
    },
  },
};
</script>
