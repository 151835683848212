<template>
  <div class="tw-bg-gray-100 tw-h-full tw-w-full">
    <navbar></navbar>
    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
export default {
  components: { Navbar },
};
</script>
