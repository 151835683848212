<template>
  <div>
    <admin-navbar :activeBar="0"></admin-navbar>
    <div class="tw-w-full">
      <h1>dashboard</h1>
    </div>
  </div>
</template>

<script>
import AdminNavbar from "../../components/admin/AdminNavbar.vue";
export default {
  components: { AdminNavbar },
  data() {
    return {};
  },
};
</script>
<style></style>
