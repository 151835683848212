import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userAccessToken: null,
    adminAccessToken: null,
  },
  getters: {
    getUserAccessToken: (state) => state.userAccessToken,
    getAdminAccessToken: (state) => state.adminAccessToken,
  },
  mutations: {
    setUserAccessToken(state, payload) {
      state.userAccessToken = payload;
      sessionStorage.setItem("UserAccessToken", payload);
    },
  },
  actions: {},
  modules: {},
});
