<template>
  <nav>
    <v-app-bar color="primary" fixed elevation="4" dark app>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title
        class="text-uppercase tw-text-white tw-text-lg tw-font-bold"
        >MILANDHOO POLICE</v-toolbar-title
      >
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" temporary app>
      <v-list nav dense>
        <v-list-item-group v-model="selectActive" color="primary">
          <router-link to="/">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-dots-grid</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="tw-capitalize"
                  >Dashboard</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/interaction">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-file-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="tw-capitalize"
                  >interaction</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/filter-records">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-filter</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="tw-capitalize"
                  >filter records</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <div
            class="tw-flex tw-flex-row tw-justify-center tw-items-center tw-cursor-default tw-py-2"
          >
            <v-divider></v-divider>
            <div class="tw-px-2 tw-text-sm tw-font-semibold tw-text-gray-600">
              Locals
            </div>
            <v-divider></v-divider>
          </div>

          <router-link to="/add-person">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="tw-capitalize"
                  >Add Person</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/edit/person">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="tw-capitalize"
                  >Edit Person</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <div
            class="tw-flex tw-flex-row tw-justify-center tw-items-center tw-cursor-default tw-py-2"
          >
            <v-divider></v-divider>
          </div>

          <router-link to="/logout">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-exit-to-app</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="tw-capitalize"
                  >Logout</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    selectActive: 0,
    items: [
      {
        text: "Filter Records",
        icon: "mdi-account-filter",
        path: "filter-records",
      },
    ],
  }),
};
</script>

<style></style>
